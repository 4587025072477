import React, { useEffect, useState } from 'react';
import { Blocks } from 'react-loader-spinner';
import { useNavigate } from 'react-router-dom';
import userService from '../services/user.service';
import { Modal, ModalBody, ModalOverlay, ModalContent, ModalHeader, Flex} from '@chakra-ui/react';

const LoadingDays = () => {
  const [retryCount, setRetryCount] = useState(0);
  const plan_id = localStorage.getItem('plan_id')
  const navigate = useNavigate()
  const isLoading = true;

  const getDays = async () => {
    const response = await userService.getDays(plan_id);

    // Check if the response data is not an empty array
    if (response.data && response.data.length > 1) {
      // Navigate to the "generate-trip/days" route
      navigate('/generate-trip/days');
    } else if (retryCount < 5) {
      // Retry up to 3 times with a delay
      setTimeout(() => {
        setRetryCount(retryCount + 1);
        getDays();
      }, 30000); // 20 seconds (you can adjust the delay as needed)
    }
  };

  useEffect(() => {
    getDays();
  }, []);

  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100vh',
      }}
    >
        <Modal isOpen={isLoading}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Loading trip plan</ModalHeader>
          <ModalBody>
          <Flex justifyContent="center" alignItems="center">
            <Blocks
                visible={true}
                height={250}
                width={250}
                ariaLabel="blocks-loading"
                wrapperStyle={{}}
                wrapperClass="blocks-wrapper"
            />
          </Flex>
          </ModalBody>
        </ModalContent>
      </Modal>
    </div>
  );
};

export default LoadingDays;
