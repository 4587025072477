import React from "react";
import GoogleMapReact from 'google-map-react';
import { FaMapMarkerAlt } from 'react-icons/fa';
import { Icon, VStack, Text, SkeletonText, Heading } from "@chakra-ui/react";
import { GoogleMap, useJsApiLoader, Marker, DirectionsRenderer } from '@react-google-maps/api';
import { useState, useCallback } from "react";
import { useEffect } from "react";
import userService from "../services/user.service";
import { selectPlaces } from "../slices/placeSlice";
import { useSelector } from "react-redux";

export default function MapNew({restLatitude, restLongitude, MarkerIcon}){
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: "AIzaSyA9ru9E-7hJeCIIVHifYevaCzCalBnzJfw"
  })

  const places = useSelector(selectPlaces)

  const center = {
    lat: places[0].latitude,
    lng: places[0].longitude
  }

  const containerStyle = {
    width: '100%',
    height: '500px'
  };

  const [map, setMap] = useState()
  const [directionsResponse, setDirectionsResponse] = useState(null)
  const [distance, setDistance] = useState('')
  const [duration, setDuration] = useState('')
  const plan_id = localStorage.getItem('plan_id')

  const calculateRoute = async () => {
    // eslint-disable-next-line
    const directionsService = new window.google.maps.DirectionsService
    let waypoints = [];
    
    for (const place of places) {
      if (place.latitude && place.longitude) {
        waypoints.push({
          location: { lat: place.latitude, lng: place.longitude },
        });
      }
    }
    // Set the first waypoint as the origin and the last waypoint as the destination
    const origin = `${waypoints[0].location.lat},${waypoints[0].location.lng}`;
    const destination = `${waypoints[waypoints.length - 1].location.lat},${waypoints[waypoints.length - 1].location.lng}`;

    // Remove the first and last waypoints from the array as they are now origin and destination
    waypoints.shift();
    waypoints.pop();
    const results = await directionsService.route({
      origin: origin,
      destination: destination,
      // eslint-disable-next-line
      travelMode: window.google.maps.TravelMode.DRIVING,
      waypoints: waypoints,
      optimizeWaypoints:true,
    })
    if (results) {
      // const routeBounds = results.routes[0].bounds;
      // const southwest = { lat: routeBounds.Oa.lo, lng: routeBounds.Oa.hi };
      // const northeast = { lat: routeBounds.mb.lo, lng: routeBounds.mb.hi };
      // const bounds = `${southwest.lat},${southwest.lng}|${northeast.lat},${northeast.lng}`;
      setDirectionsResponse(results)
      // setDistance(results.routes[0].legs[0].distance.text)
      // setDuration(results.routes[0].legs[0].duration.text)
    }
  }

  useEffect(() => {
    const fetchData = async () => {
      if (isLoaded) {
        await calculateRoute(); // Assuming calculateRoute is an asynchronous function
      }
    };
  
    fetchData(); // Call the fetchData function
  }, [isLoaded]);

  
  const onLoad = useCallback(function callback(map) {
    // This is just an example of getting and using the map instance!!! don't just blindly copy!
    // eslint-disable-next-line
    const bounds = new window.google.maps.LatLngBounds(center);
    map.fitBounds(bounds);

    setMap(map)
  }, [])

  const onUnmount = useCallback(function callback(map) {
    setMap(null)
  }, [])

  // eslint-disable-next-line
  const iconSize = isLoaded && new window.google.maps.Size(32, 32);

  return isLoaded ? (
    <VStack>
      {/* <Text>Distance: {distance} </Text>
      <Text>Duration: {duration} </Text> */}
      <GoogleMap
        mapContainerStyle={containerStyle}
        center={{ lat: parseFloat(restLatitude), lng: parseFloat(restLongitude) }}        
        zoom={10}
        onLoad={onLoad}
        onUnmount={onUnmount}
        options={{
          zoomControl: false,
          streetViewControl: false,
          mapTypeControl: false,
          fullscreenControl: false,
        }}
      >
        { /* Child components, such as markers, info windows, etc. */ }
        {directionsResponse && (
          <>
            <DirectionsRenderer directions={directionsResponse} />
            <Marker
              icon={{
                url: MarkerIcon,
                scaledSize: iconSize,
              }}
              position={{ lat: parseFloat(restLatitude), lng: parseFloat(restLongitude) }}
              // Rest of your Marker properties
            />
          </>
        )}
      </GoogleMap>
    </VStack>
) : <><SkeletonText/></>
}