import { Route, Navigate, Outlet } from "react-router-dom";
import React from 'react'
import { useAuthContext } from "./contexts/AuthContext";

const PrivateRoutes = ({children, ...rest}) => {
    const {user} = useAuthContext()

  return (
    user ? <Outlet/> : <Navigate to='/login'/>
  )
}

export default PrivateRoutes