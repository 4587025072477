// Authentication service
import axios from "axios";
import dayjs from 'dayjs';
import jwt_decode from "jwt-decode";
import authHeader from "./auth-header";
import { API_URL } from "./user.service";

const API_AUTH_URL = `${API_URL}auth/`;


class AuthService {
  getUserInfo ()  {
    return axios.get(API_AUTH_URL + 'users/me/', {
        headers: authHeader()
    }).then(
      response => {
        localStorage.setItem('email', response.data.email);
        localStorage.setItem('first_name', response.data.first_name);
        localStorage.setItem('last_name', response.data.last_name);
        localStorage.setItem('is_staff', response.data.is_staff);
        console.log(response.data)
        return response.data
      }
    );
  };

  login(credentials) {
    return axios
      .post(API_AUTH_URL + "jwt/create/", credentials)
      .then(response => {
        if (response.data.access) {
          localStorage.setItem("access_token", JSON.stringify(response.data.access));
          localStorage.setItem('refresh_token', JSON.stringify(response.data.refresh));
          console.log('After saving to localStorage:', localStorage.getItem("access_token"));

          return response.data
        } else {
          throw new Error('Login failed. Please check your credentials.'); // Throw an error if login fails
        }
      });
  }

  refresh() {
    const refresh_token = JSON.parse(localStorage.getItem('refresh_token'))

    if (!refresh_token){
        this.logout()
    }
    else{
        const user =  jwt_decode(refresh_token)
        const isExpired = dayjs.unix(user.exp).diff(dayjs()) < 1
        if (isExpired) {
          this.logout()
          return "You have been inactive on the site for too long, you need to log in again";
        } else {
          // console.log(refresh_token)
          return axios
              .post(API_AUTH_URL + "jwt/refresh/", {'refresh':refresh_token})
              .then(response => {
                  if (response.data.access) {
                    localStorage.setItem("access_token", JSON.stringify(response.data.access));
                    // localStorage.setItem("refresh_token", JSON.stringify(response.data.access));
                  }

                  return response.data;
              });
        }

    }
  }

  logout() {
    localStorage.clear()
    return "Logout Successful!"
  }

  register(credentials) {
    return axios.post(API_AUTH_URL + "users/", credentials)
    .then(response => {
      if (response.status === 201) {
        localStorage.setItem('email', credentials.email);
      }

      return response.data
    });;
  }

  getCurrentUser() {
    return localStorage.getItem('access_token');
  }

  activationEmail(credentials){
    return axios.post(API_AUTH_URL + "users/activation/", credentials)
  }

  resendEmail(){
    return axios.post(API_AUTH_URL + "users/resend_activation/", {email: localStorage.getItem('email')});
  }

  resendActivationEmail(credentials){
    return axios.post(API_AUTH_URL + "users/resend_activation/", credentials);
  }

  resetPassword(credentials){
    return axios.post(API_AUTH_URL + "users/reset_password/", credentials);
  }

  resetPasswordConfirm(credentials){
    return axios.post(API_AUTH_URL + "users/reset_password_confirm/", credentials);
  }

  googleAuthenticate(credentials){
    const config = {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    }
    const formBody = Object.keys(credentials).map(key => encodeURIComponent(key) + '=' + encodeURIComponent(credentials[key])).join('&') // making it URL Friendly

    return axios.post(API_AUTH_URL + 'o/google-oauth2/', formBody, config)
    .then(response => {
      if (response.data.access) {
        localStorage.setItem("access_token", JSON.stringify(response.data.access));
        localStorage.setItem('refresh_token', JSON.stringify(response.data.refresh));
      }

      return response.data;
    });
  }

  continueWithGoogle(){
    return axios.get(API_AUTH_URL + 'o/google-oauth2/?redirect_uri=http://localhost:8000')
  }

  setPassword(credentials)  {
    return axios.post(API_AUTH_URL + 'users/set_password/', credentials, {
        headers: authHeader()
    }).then(
      response => {
        console.log(response.data)
        if (response.status == 204){
          return 'Done'
        } else {
          return "Failed"
        }
      }
    );
  };
}

export default new AuthService();