import React, { useEffect } from 'react';
// import { useAuth0 } from '@auth0/auth0-react';
import { useNavigate } from 'react-router-dom';
import { Box, Button, Input, Flex, Icon, VStack, Image } from '@chakra-ui/react';
import AuthService from '../services/auth.service';
import { FaMagnifyingGlassLocation } from "react-icons/fa";
import styles from '../styles/index.module.scss';

import background from '../assets/background.png';
import logo from '../assets/logo.png';
import { SearchIcon } from '@chakra-ui/icons';
import { useAuthContext } from '../contexts/AuthContext';

function Home() {
  // const { isAuthenticated, loginWithRedirect, logout } = useAuth0();
  const {user, updateAuth} = useAuthContext()
  const navigate = useNavigate()

  const handleClick = () => {
    console.log(user)
    user ? navigate('/form') : navigate('/login')
  }

  return (
    <>
      <Box 
        bgImage={background}
        bgRepeat="no-repeat" 
        bgSize="contain" 
        bgPosition="center 30vh"  // Adjust the vertical position of the bg image
        width="100vw"
        height="100vh"
      >
        <Box style={{height:'50%', alignItems:'center', justifyContent:'center',}}>
          <VStack style={{height:'100%', alignItems:'center', justifyContent:'center',}}>
            <Image src={logo} width={'20vw'} />
            <Flex 
              width="100vw" 
              alignItems="center" 
              justifyContent="center"
            >
              <Input
                variant='filled' 
                placeholder='Where would you like to go?'
                width={'45%'} 
                bgColor='#D9D9D9'
                _focus={{
                  borderColor: '#D9D9D9',  // or any other color you prefer
                  bg: '#D9D9D9'
                }} 
              />
              <Button onClick={handleClick}><Icon as={SearchIcon} boxSize={6} ml={'2vw'}/></Button> {/* Adjust boxSize as needed */}
            </Flex>
          </VStack>
        </Box>
      </Box>
    </>
  );
}

export default Home;
