import React, { useState } from 'react';
import {
  Box,
  Flex,
  Text,
  Badge,
  VStack,
  Spacer,
  Icon,
} from '@chakra-ui/react';
import { MdCheckCircle } from 'react-icons/md'; // Import the check circle icon

const PlanFlight = ({ flight }) => {
  // Convert the departure time to a more readable format
  const formattedDepartureTime = new Date(flight.departure_time).toLocaleString();

  return (
    <Box
      p={4}
      width={250}
      borderWidth="1px"
      borderRadius="lg"
      boxShadow="md"
      transition="transform 0.2s, border-color 0.2s, box-shadow 0.2s"
      borderColor='purple.500'
    >
      <Flex  direction='column'>
        <Text fontWeight="bold" fontSize="lg">
          Airline: {flight.airline}
        </Text>
        <VStack spacing={2} align="start">
          <Text fontSize="md">
            Departure Time: {formattedDepartureTime}
          </Text>
          <Text fontSize="md">
            Flight Duration: {flight.flight_duration}
          </Text>
          <Text fontSize="md">
            Type: {flight.type}
          </Text>
        </VStack>
        <Spacer />
        <Flex my={2} justify="space-between">
          <Badge colorScheme="purple" fontSize="1rem">
            Stops: {flight.number_of_stops}
          </Badge>
          <Badge colorScheme="purple" fontSize="1rem">
            {flight.price}
          </Badge>
        </Flex>
      </Flex>
    </Box>
  );
};

export default PlanFlight;
