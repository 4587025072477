import React, { useState, useEffect, useCallback, forwardRef } from 'react';
import {
  VStack,
  Heading,
  Icon,
  Button,
  HStack,
  Text,
  Image,
  Link,
  Flex,
  Badge,
  Box,
  Img,
} from '@chakra-ui/react';
import { ChevronLeftIcon, ChevronRightIcon } from '@chakra-ui/icons';
import userService from '../services/user.service';
import { Flashcard } from 'react-quizlet-flashcard';
import './style.css';
import Rating from '../sections/Rating';
import { useDispatch, useSelector } from 'react-redux';
import { dayAdded, selectDay } from '../slices/daySlice';

import { selectPlaces, placesAdded } from '../slices/placeSlice';
import { selectNewPlaces, newPlacesAdded } from '../slices/newPlacesSlice';

import { NavLink, useNavigate, useParams, useLocation } from 'react-router-dom';
import { Oval } from 'react-loader-spinner';
import styles from '../styles/index.module.scss';
import { FaMapMarkerAlt } from 'react-icons/fa';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
} from '@chakra-ui/react';
import MapPlaces from '../sections/Map_places';
import MapNew from '../sections/MapNew';
import Flashcardlist from './flashcardlist';
import MarkerIcon from '../assets/building.png';
import Cards from './cards';
import CardsPlan from './cardsPlan';

export const LocationMapModal = ({ isOpen, onClose, place }) => {
  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose} size="5xl">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Map</ModalHeader>
          <ModalCloseButton />
          <ModalBody width="100%">
            {place && (
              <MapNew
                restLatitude={place.latitude}
                restLongitude={place.longitude}
                MarkerIcon={MarkerIcon}
              />
            )}
          </ModalBody>

          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={onClose}>
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

const AddPlace = () => {
  const plan_id = localStorage.getItem('plan_id');
  const { isOpen, onOpen, onClose } = useDisclosure();

  const { cP } = useParams();
  const [currentPlace, setCurrentPlace] = useState(0);

  const navigate = useNavigate();
  const location = useLocation()
  const currentPath = location.pathname;
  const isGenerateTrip = currentPath.includes('generate-trip');
  const isMyPlans = currentPath.includes('my-plans');

  const selectedDestinationCity = isGenerateTrip ? localStorage.getItem('selectedDestinationCity') : localStorage.getItem('plan_name')

  const currentDay = useSelector(selectDay);

  const dispatch = useDispatch();
  const places = useSelector(selectPlaces);
  const newPlaces = useSelector(selectNewPlaces);
  const [neoPlaces, setNeoPlaces] = useState([]);

  const [visiblePlaces, setVisiblePlaces] = useState(3); // State to manage the number of visible places
  const [canFetch, setCanFetch] = useState(true);

  const getPlaces = async () => {
    console.log(places)
    if (places.length > 0) {
      const data = {
        // Ahmed - getting the longitude and latitude of the currentPlace
        latitude: places[cP].latitude,
        longitude: places[cP].longitude,
      };
      const response = await userService.getNewPlaces(data);
      setNeoPlaces(response.data);
      dispatch(newPlacesAdded(response.data));
    }
  };

  useEffect(() => {
    if (canFetch) {
      getPlaces();

      // Set canFetch to false to prevent immediate subsequent calls
      setCanFetch(false);

      // Set a timeout to reset canFetch after 3 seconds
      const timeoutId = setTimeout(() => {
        setCanFetch(true);
      }, 8000);

      // Clear the timeout in case the component unmounts or the dependencies change
      return () => clearTimeout(timeoutId);
    }
  }, [currentDay, plan_id]);

  // const handleNextPlaceClick = () => {
  //   if (currentPlace < newPlaces.length - 1) {
  //     setCurrentPlace(currentPlace + 1);
  //   }
  // };

  // const handleBackPlaceClick = () => {
  //   if (currentPlace > 0) {
  //     setCurrentPlace(currentPlace - 1);
  //   }
  // };

  const handleLocationMarkerClick = () => {
    onOpen();
  };

  // const handleDining = () => {
  //   navigate('/generate-trip/restaurants')
  // }

  // const handleSave = async () => {
  //   const day_id = currentDay.day_id;
  //   const data = {
  //     ...newPlaces[currentPlace], // Spread the properties from newPlaces[currentPlace]
  //     day: currentDay.day_id, // Include day_id in the data
  //     plan: plan_id, // Include plan_id in the data
  //   };
  //   const response = await userService.addPlace(plan_id, day_id, data);
  //   console.log(response.data);
  // };

  const handleBackToMain = () => {
    dispatch(newPlacesAdded([]));
    if (isGenerateTrip) {
      navigate('/generate-trip/days');
    } else if (isMyPlans) {
      navigate('/my-plans/plan-days');
    }
  };

  const handleLoadMore = () => {
    setVisiblePlaces(newPlaces.length);
  };

  return (
    isMyPlans ? 
      (<VStack spacing={1} width='100%'>
        <VStack
          width="90%"
          spacing={2}
          alignItems="center"
          justifyContent="center"
          m={5}
        >
          <LocationMapModal
            isOpen={isOpen}
            onClose={onClose}
            place={newPlaces[currentPlace]}
          />
          <HStack
            style={{ alignItems: 'center', justifyContent: 'space-between' }}
            width="100%"
            spacing={5}
          >
            {selectedDestinationCity &&<VStack spacing={2} alignItems="center" justifyContent="center">
              <Heading width="100%" fontWeight="600">
                your trip to:
              </Heading>
              <Heading width="100%" fontWeight="700" fontSize="50">
                {selectedDestinationCity}
              </Heading>
            </VStack>}
            <Box display="flex" alignItems="center" >
              <Heading Heading as="h1" size="xl" width="100%" textAlign="center">
                More Attractions
              </Heading>
            </Box>
            <Box display="flex" alignItems="center" w={100}>
              {' '}
              {/* Use Box to contain "where am I staying?" */}
              <Button
                onClick={() => {
                  dispatch(newPlacesAdded([]));
                  getPlaces();
                }}
              >
                Load
              </Button>
            </Box>
          </HStack>

          <HStack
            style={{ alignItems: 'center', justifyContent: 'space-between' }}
            width="100%"
            spacing={5}
            mb={5}
          >
            <Button // Wrap the icon in a Button
              w={100} // Adjust the width as needed
              h={12} // Adjust the height as needed
              color="black" // Set the color to black
              variant="ghost" // Use "ghost" variant to make it transparent
              onClick={handleLocationMarkerClick} // Add the click handler if needed
            >
              <Icon as={FaMapMarkerAlt} w={10} h={10} color="purple" />{' '}
              {/* Use color="inherit" to maintain black color */}
            </Button>
          </HStack>

          {newPlaces.length == 0 ? (
            <Oval
              height={80}
              width={80}
              color="#4fa94d"
              secondaryColor="#4fa94d"
              strokeWidth={2}
              strokeWidthSecondary={2}
            />
          ) : (
            <>
              {/* <Flashcardlist
                handleBackPlaceClick={handleBackPlaceClick}
                handleNextPlaceClick={handleNextPlaceClick}
                places={newPlaces}
                currentPlace={currentPlace}
              /> */}
              {isGenerateTrip && <Cards places={newPlaces.slice(0, visiblePlaces)} />}
              {isMyPlans && <CardsPlan places={newPlaces.slice(0, visiblePlaces)} />}
            </>
          )}
          <HStack mt={10} spacing={5} justify="space-between" width="100%">
            <Button colorScheme="purple" onClick={handleBackToMain}>
              Back to Main
            </Button>
            <Button colorScheme="purple" onClick={handleLoadMore}>
              Load More
            </Button>
          </HStack>
        </VStack>
      </VStack>)
      :
      (<VStack
        width="90%"
        spacing={2}
        alignItems="center"
        justifyContent="center"
        m={5}
      >
        <LocationMapModal
          isOpen={isOpen}
          onClose={onClose}
          place={newPlaces[currentPlace]}
        />
        <HStack
          style={{ alignItems: 'center', justifyContent: 'space-between' }}
          width="100%"
          spacing={5}
        >
          {selectedDestinationCity &&<VStack spacing={2} alignItems="center" justifyContent="center">
            <Heading width="100%" fontWeight="600">
              your trip to:
            </Heading>
            <Heading width="100%" fontWeight="700" fontSize="50">
              {selectedDestinationCity}
            </Heading>
          </VStack>}
          <Box display="flex" alignItems="center" >
            <Heading Heading as="h1" size="xl" width="100%" textAlign="center">
              More Attractions
            </Heading>
          </Box>
          <Box display="flex" alignItems="center" w={100}>
            {' '}
            {/* Use Box to contain "where am I staying?" */}
            <Button
              onClick={() => {
                dispatch(newPlacesAdded([]));
                getPlaces();
              }}
            >
              Load
            </Button>
          </Box>
        </HStack>

        <HStack
          style={{ alignItems: 'center', justifyContent: 'space-between' }}
          width="100%"
          spacing={5}
          mb={5}
        >
          <Button // Wrap the icon in a Button
            w={100} // Adjust the width as needed
            h={12} // Adjust the height as needed
            color="black" // Set the color to black
            variant="ghost" // Use "ghost" variant to make it transparent
            onClick={handleLocationMarkerClick} // Add the click handler if needed
          >
            <Icon as={FaMapMarkerAlt} w={10} h={10} color="purple" />{' '}
            {/* Use color="inherit" to maintain black color */}
          </Button>
        </HStack>

        {newPlaces.length == 0 ? (
          <Oval
            height={80}
            width={80}
            color="#4fa94d"
            secondaryColor="#4fa94d"
            strokeWidth={2}
            strokeWidthSecondary={2}
          />
        ) : (
          <>
            {/* <Flashcardlist
              handleBackPlaceClick={handleBackPlaceClick}
              handleNextPlaceClick={handleNextPlaceClick}
              places={newPlaces}
              currentPlace={currentPlace}
            /> */}
            {isGenerateTrip && <Cards places={newPlaces.slice(0, visiblePlaces)} />}
            {isMyPlans && <CardsPlan places={newPlaces.slice(0, visiblePlaces)} />}
          </>
        )}
        <HStack mt={10} spacing={5} justify="space-between" width="100%">
          <Button colorScheme="purple" onClick={handleBackToMain}>
            Back to Main
          </Button>
          <Button colorScheme="purple" onClick={handleLoadMore}>
            Load More
          </Button>
        </HStack>
      </VStack>)
  );
};

export default AddPlace;
