import React, { useState } from 'react';
import {
  Box,
  Button,
  FormControl,
  Input,
  VStack,
  Image,
  Heading,
  Alert,
  Text,
  FormLabel,
  FormErrorMessage
} from '@chakra-ui/react';
import styles from '../styles/index.module.scss';
import cancelIcon from '../assets/cancelIcon.svg';
import Logo from '../assets/logo.png';
import { useFormik } from 'formik';
import { useNavigate, NavLink } from 'react-router-dom';
import * as Yup from 'yup';
import AuthService from '../services/auth.service';
import { useAuthContext } from '../contexts/AuthContext';

const validationSchema = Yup.object().shape({
  email: Yup.string().email('Invalid email address').required('Email is required'),
  password: Yup.string().required('Password is required'),
});

function LoginPage() {
  const navigate = useNavigate();
  const [loginError, setLoginError] = useState(null);   
  const {updateAuth} = useAuthContext() 

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    validationSchema,
    onSubmit: async (values) => {
      try {
          const response1 = await AuthService.login(values);
          if (response1){
              const response2 = await AuthService.getUserInfo();
              if (response2){
                  navigate('/form')
                  window.location.reload()
              }
          }
          // Handle successful registration here
          // console.log('Login response:', response1);
      } catch (error) {
        // Handle login error here
        console.error('Login error:', error);
        setLoginError('Login failed. Please check your credentials.'); // Set the error message
      }
    },
  });

  return (
    <Box>
      <Box className={styles.modalWrapper}>
        <VStack spacing={4}>
          <Box
            display={'flex'}
            w={'100%'}
            alignItems={'center'}
            justifyContent={'start'}
            gap={10}
          >
            <Heading fontWeight="500" fontSize="30" width='100%' textAlign='center' >
              Login
            </Heading>
          </Box>
          <form style={{width:'100%'}} onSubmit={formik.handleSubmit}>
                {loginError && (
                <Alert status="error" sx={{ mb: 2 }}>
                    {loginError}
                </Alert>
                )}
            <FormControl isInvalid={formik.touched.email && formik.errors.email}>
            <Input
              className={styles.inputField}
              id="email"
              name="email"
              type="email" // Use type="email" for email inputs
              placeholder="Email Address"
              autoComplete="email"
              value={formik.values.email}
              onChange={formik.handleChange}
              required
            />
            <FormErrorMessage>{formik.errors.email}</FormErrorMessage>
          </FormControl>
            <FormControl isInvalid={formik.touched.password && formik.errors.password}>
              <Input
                className={styles.inputField}
                type="password"
                placeholder="Password"
                name="password"
                id="password"
                value={formik.values.password}
                onChange={formik.handleChange}
                width="100%"
                required
              />
              <FormErrorMessage>{formik.errors.password}</FormErrorMessage>
            </FormControl>
            <NavLink to='/reset-password'><Text marginBottom={3} color={'blue.400'}>Forgot password?</Text></NavLink>
            <NavLink to='/signup'><Text color={'blue.400'}>Don`t have an account?</Text></NavLink>
            <Box w={'100%'} display={'flex'} justifyContent={'center'}>
              <Button className={styles.saveDeatilsButton} type="submit">
                Confirm
              </Button>
            </Box>
          </form>
        </VStack>
      </Box>
    </Box>
  );
}

export default LoginPage;
