import { VStack, Text, Button } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react'
import AuthService from '../services/auth.service';
import { Helmet } from 'react-helmet-async';
import styles from '../styles/index.module.scss';
import CustomModal from '../sections/CustomModal'

const Resend_Email = () => {
    const [isButtonDisabled, setButtonDisabled] = useState(false);
    const [open, setOpen] = useState(false)

    const handleClose = () => {
        setOpen(false);
      };

  async function handleClick(){
    try{
        const response = await AuthService.resendEmail()
        // console.log(response)
        setOpen(true);
        setButtonDisabled(true);
        setTimeout(() => {
            setButtonDisabled(false); // Enable the button after 30 seconds
          }, 30000);
    }catch(e){
        throw e
    }
  }

  return (
    <>
      <Helmet>
        <title>Resend Email</title>
        <meta name="description" content="Activation email sent. Check your inbox for instructions to activate your account and unlock the full range of features and services." />
      </Helmet>
      <VStack width='100%' spacing={5} height='60vh' justifyContent='center' alignItems='center'  >
        <Text fontSize='2xl' width='100%'  textAlign='center' p={5}>
          Thank you for registering! A verification email has been sent to your email address. Please check your inbox and follow the instructions to verify your email.
        </Text>
        <Button className={styles.saveDeatilsButton} disabled={isButtonDisabled} onClick={handleClick}>Resend Email</Button>
        <CustomModal isOpen={open} onClose={handleClose} title='Resend Email' description='Activation Email Sent, wait 30 seconds to resend again!'/>
      </VStack>
    </>
  )
}

export default Resend_Email