import { useState } from 'react';
import {
  FormControl,
  FormLabel,
  Input,
  Select,
  Button,
  VStack,
  HStack,
  FormErrorMessage
} from '@chakra-ui/react';
import 'react-datepicker/dist/react-datepicker.css';
import { useLocation } from 'react-router-dom';
import {
    Box,
    Text,
    useColorModeValue,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalCloseButton,
    ModalBody,
    ModalFooter,
    Image,
    Stack,
    Flex, useToast, Center,
    Heading,
    Divider,

    Stat, StatLabel, StatNumber, 
    Badge,
    
  } from '@chakra-ui/react';
  import visa from '../assets/visa.png';
  import creditCard from '../assets/creditcard.jpeg';

  import userService from '../services/user.service';
import { useDispatch, useSelector } from 'react-redux';
import { selectDay } from '../slices/daySlice';

const GuestForm = () => {
    const location = useLocation();
    const hotelDetails = location.state && location.state.hotelDetails;
    const offer_id = location.state && location.state.offer_id;
    const toast = useToast()
    const currentDay = useSelector(selectDay)

  const [guest, setGuest] = useState({ id:1,
    name: { title: '', firstName: '', lastName: '' },
    contact: { phone: '', email: '' },
  });
  const [payment, setPayment] = useState({id:1,
    method: 'creditCard',
    card: { vendorCode: '', cardNumber: '', expiryDate: '' },
  });
  const [errors, setErrors] = useState({});

  const handleGuestChange = (e) => {
    const { name, value } = e.target;
    setGuest((prevState) => ({
      ...prevState,
      name: {
        ...prevState.name,
        [name]: value,
      },
    }));
  };

  const handleContactChange = (e) => {
    const { name, value } = e.target;
    setGuest((prevState) => ({
      ...prevState,
      contact: {
        ...prevState.contact,
        [name]: value,
      },
    }));
  };

  const handlePaymentChange = (e) => {
    const { name, value } = e.target;
    setPayment((prevState) => ({
      ...prevState,
      card: {
        ...prevState.card,
        [name]: value,
      },
    }));
  };

  const handleDateChange = (expiryDate) => {
    setPayment((prevState) => ({
      ...prevState,
      card: {
        ...prevState.card,
        expiryDate: expiryDate,
      },
    }));
  };

  const handleSubmit = (e) => {
    console.log(guest,payment);
    e.preventDefault();
    const expiryDate = `${expiryYear}-${expiryMonth}`; // Make sure expiryYear and expiryMonth are defined
    setPayment((prevState) => ({
        ...prevState,
        card: {
            ...prevState.card,
            expiryDate: expiryDate,
        },
    }));
    handleOfferSelection(offer_id)
    
    // Handle form submission
  };
  const handleOfferSelection = async (offer_id) => {
      const inputData = {
      
        offerId:offer_id,
        guest:guest,payment:payment
        };
    //   const plan = localStorage.getItem('plan_id');
      try {
      const response = await userService.bookHotel(inputData);
      console.log(response.data);
      toast({
          title: 'Hotel Booked Successfully',
          description: `The hotel was saved for Day ${currentDay.day_number + 1}.`,
          status: 'success',
          duration: 9000,
          isClosable: true,
      })
      // navigate('/generate-trip/days')
    //   navigate('/bookHotel')

      } catch {
    //   toast({
    //       title: 'Hotel Selection Unsuccessful',
    //       description: "Some Error occured. Please try again",
    //       status: 'error',
    //       duration: 9000,
    //       isClosable: true,
    //   })
      }

  }
  const validateForm = () => {
    let formErrors = {};
    if (!guest.name.firstName.trim()) {
      formErrors = { ...formErrors, firstName: 'First name is required' };
    }
    if (!guest.name.lastName.trim()) {
      formErrors = { ...formErrors, lastName: 'Last name is required' };
    }
    if (!guest.contact.phone.trim()) {
      formErrors = { ...formErrors, phone: 'Phone number is required' };
    }
    if (!guest.contact.email.trim()) {
      formErrors = { ...formErrors, email: 'Email is required' };
    }
    if (!payment.card.vendorCode.trim()) {
      formErrors = { ...formErrors, vendorCode: 'Vendor code is required' };
    }
    if (!payment.card.cardNumber.trim()) {
      formErrors = { ...formErrors, cardNumber: 'Card number is required' };
    }
    // if (!payment.card.expiryDate) {
    //   formErrors = { ...formErrors, expiryDate: 'Expiry date is required' };
    // }
    setErrors(formErrors);
    return Object.keys(formErrors).length === 0;
  };
  const [expiryMonth, setExpiryMonth] = useState('');
  const [expiryYear, setExpiryYear] = useState('');
  const handleMonthChange = (e) => {
    setExpiryMonth(e.target.value);
  };

  const handleYearChange = (e) => {
    setExpiryYear(e.target.value);
  };
  return (
    <form onSubmit={handleSubmit}>
      <VStack spacing={6} align="stretch" p={6} borderWidth={1} borderRadius="lg">
      <VStack align="stretch">
          <Heading size="md">Personal Information</Heading>
          <Divider />
      <FormControl>
          <FormLabel>Title</FormLabel>
          <Select name="title" value={guest.name.title} onChange={handleGuestChange}>
            <option value="MR">Mr</option>
            <option value="MRS">Mrs</option>
            <option value="MISS">Miss</option>
          </Select>
        </FormControl>
        <FormControl isInvalid={errors.firstName}>
          <FormLabel>First Name</FormLabel>
          <Input
            type="text"
            name="firstName"
            value={guest.name.firstName}
            onChange={handleGuestChange}
          />
          <FormErrorMessage>{errors.firstName}</FormErrorMessage>
        </FormControl>
        <FormControl isInvalid={errors.lastName}>
          <FormLabel>Last Name</FormLabel>
          <Input
            type="text"
            name="lastName"
            value={guest.name.lastName}
            onChange={handleGuestChange}
          />
          <FormErrorMessage>{errors.lastName}</FormErrorMessage>
        </FormControl>
        <FormControl isInvalid={errors.phone}>
          <FormLabel>Phone</FormLabel>
          <Input
            type="tel"
            name="phone"
            value={guest.contact.phone}
            onChange={handleContactChange}
          />
          <FormErrorMessage>{errors.phone}</FormErrorMessage>
        </FormControl>
        <FormControl isInvalid={errors.email}>
          <FormLabel>Email</FormLabel>
          <Input
            type="email"
            name="email"
            value={guest.contact.email}
            onChange={handleContactChange}
          />
          <FormErrorMessage>{errors.email}</FormErrorMessage>
        </FormControl>
        </VStack>
        <VStack align="stretch">
          <Heading size="md">Payment Details</Heading>
          <Divider />
          <HStack>
          <Image
        height={50}
        width={50}
        src={visa}
        alt="#"
    />
              <Image
        height={55}
        width={150}
        src={creditCard}
        alt="#"
    />
    </HStack>
        <FormControl>
          <FormLabel>Payment Method</FormLabel>
          <Select name="method" value={payment.method} onChange={handlePaymentChange}>
            <option value="creditCard">Credit Card</option>
            <option value="paypal">PayPal</option>
            <option value="bankTransfer">Bank Transfer</option>
          </Select>
        </FormControl>
        <FormControl isInvalid={errors.vendorCode}>
          <FormLabel>CVC</FormLabel>
          <Input
            type="text"
            name="vendorCode"
            value={payment.card.vendorCode}
            onChange={handlePaymentChange}
          />
          <FormErrorMessage>{errors.vendorCode}</FormErrorMessage>
        </FormControl>
        <FormControl isInvalid={errors.cardNumber}>
          <FormLabel>Card Number</FormLabel>
          <Input
            type="text"
            name="cardNumber"
            value={payment.card.cardNumber}
            onChange={handlePaymentChange}
          />
          <FormErrorMessage>{errors.cardNumber}</FormErrorMessage>
        </FormControl>
        <FormControl isInvalid={errors.expiryDate}>
          <FormLabel>Expiry Date</FormLabel>
          <HStack>
            <Select placeholder="Month" value={expiryMonth} onChange={handleMonthChange}>
              <option value="01">January</option>
              <option value="02">February</option>
              <option value="03">March</option>
              <option value="04">April</option>
              <option value="05">May</option>
              <option value="06">June</option>
              <option value="07">July</option>
              <option value="08">August</option>
              <option value="09">September</option>
              <option value="10">October</option>
              <option value="11">November</option>
              <option value="12">December</option>
            </Select>
            <Select placeholder="Year" value={expiryYear} onChange={handleYearChange}>
              {/* Options for years, you can adjust as needed */}
              {Array.from({ length: 10 }, (v, i) => {
                const year = new Date().getFullYear() + i;
                return (
                  <option key={year} value={year}>
                    {year}
                  </option>
                );
              })}
            </Select>
          </HStack>
          <FormErrorMessage>{errors.expiryDate}</FormErrorMessage>
        </FormControl>
        <HStack>
          <Button type="submit" colorScheme="blue" onClick={validateForm}>
            Submit
          </Button>
          <Button colorScheme="gray">Cancel</Button>
        </HStack>
        </VStack>
      </VStack>
    </form>
  );
};

export default GuestForm;
