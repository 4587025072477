import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  newPlaces: [],
};

export const newPlacesSlice = createSlice({
  name: 'newPlaces',
  initialState,
  reducers: {
    newPlacesAdded: {
      reducer(state, action) {
        state.newPlaces = action.payload; // Replace the entire places array
      },
    },
  },
});

export const selectNewPlaces = state => state.newPlaces.newPlaces;

export const { newPlacesAdded } = newPlacesSlice.actions;

export default newPlacesSlice.reducer;
