import { BsStar, BsStarFill, BsStarHalf } from 'react-icons/bs';
import { Box } from '@chakra-ui/react';

export default function Rating({ rating, numReviews }) {
    return (
      <Box display="flex" alignItems="center">
        {Array(5)
          .fill('')
          .map((_, i) => {
            const roundedRating = Math.round(rating * 2) / 2;
            if (roundedRating - i >= 1) {
              return (
                <BsStarFill
                  key={i}
                  style={{ marginLeft: '1' }}
                  color={i < rating ? '#FFBF00' : '#FFBF00'}
                />
              );
            }
            if (roundedRating - i === 0.5) {
              return (
                <BsStarHalf color="#FFBF00" key={i} style={{ marginLeft: '1' }} />
              );
            }
            return <BsStar color="#FFBF00" key={i} style={{ marginLeft: '1' }} />;
          })}
        <Box as="span" ml="2" color="gray.600" fontSize="sm">
          {numReviews} review{numReviews > 1 && 's'}
        </Box>
      </Box>
    );
  }