import React from 'react';
import { VStack, HStack, Text, Image, Link, Box } from '@chakra-ui/react';
import { Flashcard } from 'react-quizlet-flashcard';
import Rating from '../sections/Rating';

const FlashCard = ({ place }) => {
  console.log(place);
  const backPart = place ? (
    <VStack height="100%" spacing={2} p={4} borderWidth="1px" borderRadius="lg">
      <Text fontSize="2xl" fontWeight="bold" mt={4}>
        {place.name}
      </Text>

      <Text fontSize="md" color="gray.600">
        {place.address}
      </Text>

      <Text fontSize="md" mt={2}>
        {place.description}
      </Text>

      {place.rating && (
        <Rating numReviews={place.num_reviews} rating={place.rating} />
      )}

      {place.email && (
        <Text fontSize="sm" mt={2}>
          <Link href={`mailto:${place.email}`}>{place.email}</Link>
        </Text>
      )}

      {place.phone && (
        <Text fontSize="sm" mt={2}>
          <Link href={`tel:${place.phone}`}>{place.phone}</Link>
        </Text>
      )}

      {place.website && (
        <Link
          href={place.website}
          fontSize="sm"
          color="teal.500"
          isExternal
          mt={2}
        >
          Visit Website
        </Link>
      )}
    </VStack>
  ) : (
    <div></div>
  );

  const frontPart = place ? (
    <Box display="flex" justifyContent="center" alignItems="center">
      <Image
        src={place.image}
        alt={`Place Image`}
        height={400}
        objectFit="cover"
      />
    </Box>
  ) : (
    <div></div>
  );

  return (
    <HStack style={{ width: '70%' }}>
      {place && (
        <Flashcard
          className="flashcard-container"
          width="100%"
          frontCardStyle={{ height: 400 }}
          frontHTML={frontPart}
          backCardStyle={{ minHeight: '400px', overflowY: 'auto' }}
          backHTML={backPart}
        />
      )}
    </HStack>
  );
};

export default FlashCard;
