import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  day_id: 0,
  day_number: 0, // Initialize with null or any default value you prefer
};

export const daySlice = createSlice({
  name: 'day',
  initialState,
  reducers: {
    dayAdded: {
      reducer(state, action) {
        state.day_id = action.payload.day_id;
        state.day_number = action.payload.day_number
      },
    }
  }
});

export const selectDay = (state) => state.day;

export const { dayAdded } = daySlice.actions;

export default daySlice.reducer;
