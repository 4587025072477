import { VStack, Button, Text } from '@chakra-ui/react';
import React, { useState } from 'react'
import AuthService from '../services/auth.service';
import { useNavigate, useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import styles from '../styles/index.module.scss';
import CustomModal from '../sections/CustomModal';
import { accountActivated } from '../constants';

const Activate_Email = () => {
  const { uid, token } = useParams();
  const [open, setOpen] = useState(false)
  const navigate = useNavigate()

  const handleClose = () => {
    setOpen(false)
    navigate('/login')
  }

  async function handleClick(){
    try{
      const response = await AuthService.activationEmail({uid:uid, token:token})
      setOpen(true)
      // console.log(response)
    }catch(e){
      throw e
    }
  }

  return (
    <>
      <Helmet>
        <title>Activate Account</title>
        <meta name='description' content='Complete the account activation process to unlock all the benefits of your user account. Click on the "Activate" button to get started'/>
      </Helmet>
      <VStack width='100vw' spacing={5} height='100vh' justifyContent='center' alignItems='center'>
        <Text fontSize='4xl' textAlign='center'>
          Activate Your Account
        </Text>
        <Button className={styles.saveDeatilsButton} onClick={handleClick}>Click Here</Button>

        <CustomModal isOpen={open} onClose={handleClose} title='Account Activated Successfully' description={accountActivated}/>
      </VStack>
    </>
  )
}

export default Activate_Email