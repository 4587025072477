import { createContext, useState, useEffect, useContext } from 'react'
import jwt_decode from "jwt-decode";
import AuthService from '../services/auth.service';

const AuthContext = createContext()

export const useAuthContext = () => useContext(AuthContext);

export const AuthProvider = ({children}) => {
    const [authToken, setAuthToken] = useState(() => localStorage.getItem('access_token') ? JSON.parse(localStorage.getItem('access_token')) : null);
    const [loading, setLoading] = useState(true)
    const [user, setUser] = useState(() => {
        // Check if access_token is in localStorage
        const access_token = localStorage.getItem('access_token');

        if (access_token) {
            // Retrieve user properties from localStorage
            const email = localStorage.getItem('email');
            const first_name = localStorage.getItem('first_name');
            const last_name = localStorage.getItem('last_name');
            const is_staff = localStorage.getItem('is_staff');

            // Create the user object
            return {
                email,
                first_name,
                last_name,
                is_staff
            };
        } else {
            // If access_token is not in localStorage, set user to null
            return null;
        }
    });

    const updateAuth = () => {
        setAuthToken(localStorage.getItem('access_token') ? JSON.parse(localStorage.getItem('access_token')) : null);
        let access_token = localStorage.getItem('access_token')
        if (access_token) {
            // Retrieve user properties from localStorage
            const email = localStorage.getItem('email');
            const first_name = localStorage.getItem('first_name');
            const last_name = localStorage.getItem('last_name');
            const is_staff = localStorage.getItem('is_staff');

            // Create the user object
            const savedUser = {
                email: email,
                first_name: first_name,
                last_name: last_name,
                is_staff: is_staff,
            };

            setUser(savedUser);
            setContextData({'user':user, 'updateAuth':updateAuth})
        } else {
            // If access_token is not in localStorage, set user to null
            setUser(null);
            setContextData({'user':null, 'updateAuth':updateAuth})
            AuthService.logout()
            
        }
    };

    const [contextData, setContextData] = useState({
        'user':user,
        'updateAuth':updateAuth
    })


    useEffect(()=> {

        const refreshUpdate = async () => {
            const response = await AuthService.refresh()
            const response1 = await AuthService.getUserInfo()
            updateAuth()
            setContextData({'user':user, 'updateAuth':updateAuth})
            setLoading(false)
        }

        if (loading){
            if (authToken){
                refreshUpdate()
            }
            else{
                setLoading(false)
            }
        }

        let minutes = 1000 * 60 * 25

        let interval =  setInterval(()=> {

            AuthService.refresh()

        }, minutes)
        return ()=> clearInterval(interval)

    }, [])

    return(
        <AuthContext.Provider value={contextData} >
            {children}
        </AuthContext.Provider>
    )
}