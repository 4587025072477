import axios from 'axios';
import authHeader from './auth-header';
import dayjs from 'dayjs';
import jwt_decode from 'jwt-decode';
import AuthService from './auth.service';

// export const API_URL = process.env.REACT_DEVELOPMENT === 'true' ? 'http://127.0.0.1:8000/' : 'https://backend.ipass.live/';
export const API_URL = process.env.REACT_APP_REACT_DEVELOPMENT === 'false' ? 'https://backend.ipass.live/' : 'http://127.0.0.1:8000/'

class UserService {
  // PLANS
  createPlan(data) {
    return axios.post(API_URL + 'plans/', data, {
      headers: authHeader(),
    });
  }

  getPlan() {
    return axios.get(API_URL + 'plans/', {
      headers: authHeader(),
    });
  }

  getSharedPlan() {
    return axios.get(API_URL + 'collab/', {
      headers: authHeader(),
    });
  }

  deletePlan(plan_id) {
    return axios.delete(API_URL + `plans/${plan_id}/`, {
      headers: authHeader(),
    });
  }

  // FLIGHTS
  addFlight(data, plan_id) {
    return axios.post(`${API_URL}plans/${plan_id}/flights/`, data, {
      headers: authHeader(),
    });
  }

  listFlights(data) {
    return axios.post(API_URL + 'flight-list/', data, {
      headers: authHeader(),
    });
  }

  getFlights(plan_id) {
    return axios.get(`${API_URL}plans/${plan_id}/flights/`, {
      headers: authHeader(),
    });
  }

  // HOTELS

  addHotel(data, plan_id) {
    return axios.post(`${API_URL}plans/${plan_id}/hotels/`, data, {
      headers: authHeader(),
    });
  }

  listHotels(data) {
    return axios.post(API_URL + 'hotel-list/', data, {
      headers: authHeader(),
    });
  }
  bookHotel(data,plan_id) {
    return axios.put(API_URL + 'hotel-book/', data, {
      params: { plan_id },
      headers: authHeader(),
    });
  }
  PlaceList(data) {
    return axios.put(API_URL + 'place-list/', data, {
      headers: authHeader(),
    });
  }
  hotelDetails(data) {
    return axios.post(API_URL + 'hotel-details/', data, {
      headers: authHeader(),
    });
  }

  getHotels(plan_id) {
    return axios.get(`${API_URL}plans/${plan_id}/hotels/`, {
      headers: authHeader(),
    });
  }

  // DAYS

  addDay(data, plan_id) {
    return axios.post(`${API_URL}plans/${plan_id}/days/`, data, {
      headers: authHeader(),
    });
  }

  createDays(data) {
    return axios.post(API_URL + 'create-days/', data, {
      headers: authHeader(),
    });
  }

  getDays(plan_id) {
    return axios.get(`${API_URL}plans/${plan_id}/days/`, {
      headers: authHeader(),
    });
  }

  // PLACES

  getPlaces(plan_id, day_id) {
    return axios.get(`${API_URL}plans/${plan_id}/days/${day_id}/places/`, {
      headers: authHeader(),
    });
  }

  getPlacesFromPlan(plan_id) {
    return axios.get(`${API_URL}place-list?planId=${plan_id}/`);
  }

  addPlace(plan_id, day_id, data) {
    return axios.post(
      `${API_URL}plans/${plan_id}/days/${day_id}/places/`,
      data,
      {
        headers: authHeader(),
      }
    );
  }

  deletePlace(plan_id, day_id, place_id) {
    return axios.delete(`${API_URL}plans/${plan_id}/days/${day_id}/places/${place_id}/`, {
      headers: authHeader(),
    })
  }

  // RESTAURANTS

  listRestaurants(data) {
    return axios.post(API_URL + 'restaurant-list/', data, {
      headers: authHeader(),
    });
  }

  restaurantDetails(data) {
    return axios.post(API_URL + 'restaurant-details/', data, {
      headers: authHeader(),
    });
  }

  addRestaurant(data, plan_id) {
    return axios.post(`${API_URL}plans/${plan_id}/restaurants/`, data, {
      headers: authHeader(),
    });
  }

  getRestaurants(plan_id) {
    return axios.get(`${API_URL}plans/${plan_id}/restaurants/`, {
      headers: authHeader(),
    });
  }

  // New Places

  getNewPlaces(data) {
    return axios.post(`${API_URL}add-places/`, data, {
      headers: authHeader(),
    });
  }

  addUser(data) {
    return axios.post(`${API_URL}collab/`, data, {
      headers: authHeader(),
    });
  }
}

export default new UserService();
