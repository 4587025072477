import { configureStore } from "@reduxjs/toolkit";
import dayReducer from "../slices/daySlice"
import placesReducer from "../slices/placeSlice"
import newPlacesReducer from "../slices/newPlacesSlice"

export const store = configureStore({
    reducer: {
        day: dayReducer,
        places:placesReducer,
        newPlaces:newPlacesReducer
    }
})