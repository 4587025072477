import React, { useState } from 'react';
import {
  Box,
  Button,
  FormControl,
  Input,
  VStack,
  Image,
  Heading,
  Alert,
  FormErrorMessage,
  Text,
  Checkbox,
} from '@chakra-ui/react';
import styles from '../styles/index.module.scss';
import cancelIcon from '../assets/cancelIcon.svg';
import Logo from '../assets/logo.png';
import { useFormik } from 'formik';
import { useNavigate, NavLink } from 'react-router-dom';
import * as Yup from 'yup';
import AuthService from '../services/auth.service';
import { useAuthContext } from '../contexts/AuthContext';

const validationSchema = Yup.object({
    first_name: Yup.string().required('First Name is required'),
    last_name: Yup.string().required('Last Name is required'),
    email: Yup.string().email('Invalid email address').required('Email is required'),
    password: Yup.string()
        .min(8, 'Password must be at least 8 characters')
        .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[A-Za-z\d]+$/,
        'Password must contain at least one uppercase letter, one lowercase letter, and one number'
        )
        .required('Password is required'),
    re_password: Yup.string()
      .oneOf([Yup.ref('password'), null], 'Passwords must match')
      .required('Confirm Password is required'),
  });

function SignupPage() {
  const navigate = useNavigate();
  const [loginError, setLoginError] = useState(null);   
  const {updateAuth} = useAuthContext() 

  const [privacyPolicyChecked, setPrivacyPolicyChecked] = useState(false);
  const [termsChecked, setTermsChecked] = useState(false);

    const formik = useFormik({
      initialValues: {
        first_name: '',
        last_name: '',
        email: '',
        password: '',
        re_password: '',
        school:'',
      },
      validationSchema,
      onSubmit: async (values) => {
        try {
          const response = await AuthService.register(values);
          // Handle successful registration here
          // console.log('Registration response:', response);
          navigate('/resend-email')
        } catch (error) {
          // Handle registration error here
          console.error('Registration error:', error);
        }
      },
    });

  return (
    <Box>
      <Box className={styles.modalWrapper}>
        <VStack spacing={4}>
          <Box
            display={'flex'}
            w={'100%'}
            alignItems={'center'}
            justifyContent={'start'}
            gap={10}
          >
            <Heading fontWeight="500" fontSize="30" width='100%' textAlign='center' >
              Signup
            </Heading>
          </Box>
          <form style={{width:'100%'}} onSubmit={formik.handleSubmit}>
                {loginError && (
                <Alert status="error" sx={{ mb: 2 }}>
                    {loginError}
                </Alert>
                )}
            <FormControl isInvalid={formik.touched.first_name && formik.errors.first_name}>
              <Input
                className={styles.inputField}
                id="first_name"
                placeholder="First Name"
                name="first_name"
                autoComplete="given-name"
                value={formik.values.first_name}
                onChange={formik.handleChange}
                required
                width="100%"
              />
              <FormErrorMessage>{formik.errors.first_name}</FormErrorMessage>
            </FormControl>
            <FormControl isInvalid={formik.touched.last_name && formik.errors.last_name}>
              <Input
                className={styles.inputField}
                id="last_name"
                placeholder="Last Name"
                name="last_name"
                autoComplete="family-name"
                value={formik.values.last_name}
                onChange={formik.handleChange}
                required
                width="100%"
              />
              <FormErrorMessage>{formik.errors.last_name}</FormErrorMessage>
            </FormControl>
            <FormControl isInvalid={formik.touched.email && formik.errors.email}>
              <Input
                className={styles.inputField}
                id="email"
                placeholder="Email Address"
                name="email"
                autoComplete="email"
                value={formik.values.email}
                onChange={formik.handleChange}
                required
                width="100%"
              />
              <FormErrorMessage>{formik.errors.email}</FormErrorMessage>
            </FormControl>
            <FormControl isInvalid={formik.touched.password && formik.errors.password}>
              <Input
                className={styles.inputField}
                type="password"
                placeholder="Password"
                name="password"
                id="password"
                value={formik.values.password}
                onChange={formik.handleChange}
                required
                width="100%"
              />
              <FormErrorMessage>{formik.errors.password}</FormErrorMessage>
            </FormControl>
            <FormControl isInvalid={formik.touched.re_password && formik.errors.re_password}>
              <Input
                className={styles.inputField}
                name="re_password"
                placeholder="Confirm Password"
                type="password"
                id="re_password"
                autoComplete="new-password"
                value={formik.values.re_password}
                onChange={formik.handleChange}
                required
                width="100%"
              />
              <FormErrorMessage>{formik.errors.re_password}</FormErrorMessage>
            </FormControl>
            <FormControl>
              <Checkbox
                isChecked={privacyPolicyChecked}
                onChange={() => setPrivacyPolicyChecked(!privacyPolicyChecked)}
                sx={{
                  padding: '8px',
                  textColor: 'blue',
                  textDecoration: 'none', // Remove default underline
                  ':hover': {
                    textDecoration: 'underline',
                  },
                }}
              >
                  <NavLink to="/privacy-policy">I agree to the Privacy Policy</NavLink>
              </Checkbox>
            </FormControl>

            <FormControl>
              <Checkbox
                isChecked={termsChecked}
                onChange={() => setTermsChecked(!termsChecked)}
                sx={{
                  padding: '8px',
                  textColor: 'blue',
                  textDecoration: 'none', // Remove default underline
                  ':hover': {
                    textDecoration: 'underline',
                  },
                  mb:5
                }}
              >
                  <NavLink to="/terms-of-conditions">
                    I agree to the Terms of Conditions
                  </NavLink>
              </Checkbox>
            </FormControl>
            <NavLink to='/login'><Text color={'blue.400'} sx={{
              textDecoration: 'none', // Remove default underline
              ':hover': {
                textDecoration: 'underline',
              },
            }}>Already have an account?</Text></NavLink>
            <Box w={'100%'} display={'flex'} justifyContent={'center'}>
              <Button className={styles.saveDeatilsButton} type="submit" isDisabled={!(privacyPolicyChecked && termsChecked)}>
                Confirm
              </Button>
            </Box>
          </form>

        </VStack>
        {/* <Box w={'100%'} mt={4}>
          Membership: Basic
        </Box> */}

      </Box>
    </Box>
  );
}

export default SignupPage;
