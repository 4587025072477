import React, {useState} from 'react'
import Rating from './Rating'
import { Badge, Box, Image, Stack, Heading, Text, Collapse } from '@chakra-ui/react'
import { useColorModeValue } from '@chakra-ui/react'
import { ChevronDownIcon, ChevronUpIcon } from '@chakra-ui/icons'
import { Link } from 'react-router-dom'

const PlanRestaurant = ({restaurant}) => {
    const colorModeValue = useColorModeValue('white', 'gray.800');
    const [isOpen, setIsOpen] = useState()
  return (
    <Box
        role={'group'}
        p={6}
        maxW={'330px'}
        w={'full'}
        bg={colorModeValue}
        boxShadow={'2xl'}
        rounded={'lg'}
        pos={'relative'}
        zIndex={1}
        >
        <Box
            rounded={'lg'}
            mt={-12}
            pos={'relative'}
            height={'230px'}
            _after={{
            transition: 'all .3s ease',
            content: '""',
            w: 'full',
            h: 'full',
            pos: 'absolute',
            top: 5,
            left: 0,
            backgroundImage: `url(${restaurant.restaurant_image})`,
            filter: 'blur(15px)',
            zIndex: -1,
            }}
            _groupHover={{
            _after: {
                filter: 'blur(20px)',
            },
            }}
        >
            <Image
                rounded={'lg'}
                height={230}
                width={282}
                objectFit={'cover'}
                src={restaurant.restaurant_image}
                alt="#"
            />
        </Box>
        <Stack pt={10} align={'center'}>
            <Heading fontSize={'2xl'} fontFamily={'body'} fontWeight={500}>
            {restaurant.name}
            </Heading>
            {restaurant.description && <Text fontSize="sm" color="gray.600">
                {restaurant.description}
            </Text>}
            {restaurant.phone && <Badge colorScheme="purple" fontSize="1rem">
                Phone: <Link href={`tel:${restaurant.phone}`}>{restaurant.phone}</Link>
            </Badge>}
            <Text fontSize="sm" color="gray.600">
                Selected for Day {restaurant.day_number}
            </Text>
            {restaurant.rating && <Rating numReviews={restaurant.num_reviews} rating={restaurant.rating}/>}
            {restaurant.email && <Text fontSize="sm" mt={2}>
                Email: <Link href={`mailto:${restaurant.email}`}>{restaurant.email}</Link>
            </Text>}
            {restaurant.address && <Text fontSize="sm" color="gray.600">
                Address: {restaurant.address}
            </Text>}
            {restaurant.opening_hours && <Box mt={1} display="flex" alignItems="center" cursor="pointer" onClick={() => setIsOpen(!isOpen)}>
              <Text fontWeight="bold" fontSize="lg" mr={2}>
                Opening Hours
              </Text>
              {isOpen ? <ChevronUpIcon boxSize={5} /> : <ChevronDownIcon boxSize={5} />}
            </Box>}
            {restaurant.opening_hours && <Collapse in={isOpen} animateOpacity>
              <Text fontSize="sm" mt={2}>
                {restaurant.opening_hours.map((day, index) => (
                <div key={index}>{day}</div>
                ))}
              </Text>
            </Collapse>}
        </Stack>
    </Box>
  )
}

export default PlanRestaurant