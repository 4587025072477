import { Route, Navigate, Outlet } from "react-router-dom";
import React from 'react'
import { useAuthContext } from "./contexts/AuthContext";

const TripRoutes = ({children, ...rest}) => {
    const budget = localStorage.getItem('budget') 

  return (
    budget ? <Outlet/> : <Navigate to='/form'/>
  )
}

export default TripRoutes