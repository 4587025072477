import { extendTheme } from "@chakra-ui/react";

export const myTheme = extendTheme({
    colors: {
        primary: "A259FF",
        lighterPurple: "B780FC",
        lightestPurple: "CCA6F9",
        lightGrey:"F6F2F2",
        darkGray: "D9D9D9",
    }
})