import React, { useState, useEffect, useCallback, useRef } from 'react';
import {
  VStack,
  Heading,
  Icon,
  Button,
  HStack,
  Text,
  Image,
  Link,
  Flex,
  Badge,
  Box,
  useToast,
} from '@chakra-ui/react';
import { ChevronLeftIcon, ChevronRightIcon, DeleteIcon } from '@chakra-ui/icons';
import userService from '../services/user.service';
import { Flashcard } from 'react-quizlet-flashcard';
import './style.css';
import Rating from '../sections/Rating';
import { useDispatch, useSelector } from 'react-redux';
import { dayAdded, selectDay } from '../slices/daySlice';
import { selectPlaces, placesAdded } from '../slices/placeSlice';
import { NavLink, useNavigate } from 'react-router-dom';
import { Oval } from 'react-loader-spinner';
import styles from '../styles/index.module.scss';
import { FaMapMarkerAlt } from 'react-icons/fa';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
} from '@chakra-ui/react';
import MapPlaces from '../sections/Map_places';

export const LocationMapModal = ({ isOpen, onClose, places }) => {
  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose} size="5xl">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Map</ModalHeader>
          <ModalCloseButton />
          <ModalBody width="100%">
            <MapPlaces places={places} zoom={15} />
          </ModalBody>

          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={onClose}>
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export const DeleteModal = ({ isOpen, onClose, handleCancelDelete, handleConfirmDelete }) => {
  return (
    <>
      {/* Confirmation Modal */}
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Confirm Deletion</ModalHeader>
          <ModalBody>
            Are you sure you want to delete?
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="red" mr={2} onClick={handleConfirmDelete}>
              Yes
            </Button>
            <Button onClick={handleCancelDelete}>
              No
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

const Days = () => {
  const plan_id = localStorage.getItem('plan_id');
  const selectedDestinationCity = localStorage.getItem(
    'selectedDestinationCity'
  );
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { isOpen: isDeleteModalOpen, onOpen: onDeleteModalOpen, onClose: onDeleteModalClose } = useDisclosure();
  const toast = useToast()

  // const [currentDay, setCurrentDay] = useState(0);
  const [currentPlace, setCurrentPlace] = useState(0);
  const [days, setDays] = useState([]);
  // const [places, setPlaces] = useState([]);
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const currentDay = useSelector(selectDay);
  const places = useSelector(selectPlaces);

  const loadedRef = useRef(false);

  // Function to fetch the days
  const getDays = async () => {
    const response = await userService.getDays(plan_id);
    setDays(response.data);
    if (response.data.length > 0) {
      getPlacesForDay(response.data[currentPlace].id);
      // if (!loadedRef.current) {
      //   // dispatch(
      //   //   dayAdded({ day_id: response.data[currentPlace].id, day_number: 0 })
      //   // );
      //   getPlacesForDay(response.data[currentPlace].id);
      //   loadedRef.current = true
      // } else {
      //   getPlacesForDay(currentDay.day_id);
      // }
    }
  };

  const getDaysLater = async () => {
    const response = await userService.getDays(plan_id);
    setDays(response.data);
  };

  // Function to fetch places for the current day
  const getPlacesForDay = async day_id => {
    const response = await userService.getPlaces(plan_id, day_id);
    // console.log(response.data[0].id);
    dispatch(placesAdded(response.data));
  };
  

  useEffect(() => {
    if (days.length == 0) {
      getDays();
    }
  }, []);

  useEffect(() => {
    // getDaysLater();
    if (days.length > 0) {
      getPlacesForDay(currentDay.day_id);
    }
  }, [currentDay]);

  const handleNextDayClick = () => {
    getDaysLater();
    if (currentDay.day_number < days.length - 1) {
      // setCurrentDay(currentDay + 1);
      dispatch(
        dayAdded({
          day_id: days[currentDay.day_number + 1].id,
          day_number: currentDay.day_number + 1,
        })
      );
      setCurrentPlace(0); // Reset place index when changing the day
    }
  };

  const handleBackDayClick = () => {
    getDaysLater();
    if (currentDay.day_number > 0) {
      // setCurrentDay(currentDay.day_number  - 1);
      dispatch(
        dayAdded({
          day_id: days[currentDay.day_number - 1].id,
          day_number: currentDay.day_number - 1,
        })
      );
      setCurrentPlace(0); // Reset place index when changing the day
    }
  };

  const handleNextPlaceClick = () => {
    // getDaysLater();
    if (currentPlace < places.length - 1) {
      setCurrentPlace(currentPlace + 1);
    }
  };

  const handleBackPlaceClick = () => {
    // getDaysLater();
    if (currentPlace > 0) {
      setCurrentPlace(currentPlace - 1);
    }
  };

  const frontPart = places[currentPlace] ? (
    <Box display="flex" justifyContent="center" alignItems="center">
      <Image
        src={places[currentPlace].image}
        alt={`Place Image`}
        height={400}
        objectFit="cover"
      />
    </Box>
  ) : (
    <div></div>
  );

  const handleLocationMarkerClick = () => {
    onOpen();
  };

  const handleDining = () => {
    navigate('/generate-trip/restaurants');
  };

  const handleSpots = () => {
    // Ahmed - changed this url to send the currentPlace to the new page
    navigate(`/generate-trip/preview/${currentPlace}`);
  };

  const backPart = places[currentPlace] ? (
    <VStack height="100%" spacing={2} p={4} borderWidth="1px" borderRadius="lg">
      <Text fontSize="2xl" fontWeight="bold" mt={4}>
        {places[currentPlace].name}
      </Text>

      <Text fontSize="md" color="gray.600">
        {places[currentPlace].address}
      </Text>

      <Text fontSize="md" mt={2}>
        {places[currentPlace].description}
      </Text>

      {places[currentPlace].rating && (
        <Rating
          numReviews={places[currentPlace].num_reviews}
          rating={places[currentPlace].rating}
        />
      )}

      {places[currentPlace].email && (
        <Text fontSize="sm" mt={2}>
          <Link href={`mailto:${places[currentPlace].email}`}>
            {places[currentPlace].email}
          </Link>
        </Text>
      )}

      {places[currentPlace].phone && (
        <Text fontSize="sm" mt={2}>
          <Link href={`tel:${places[currentPlace].phone}`}>
            {places[currentPlace].phone}
          </Link>
        </Text>
      )}

      {places[currentPlace].website && (
        <Link
          href={places[currentPlace].website}
          fontSize="sm"
          color="teal.500"
          isExternal
          mt={2}
        >
          Visit Website
        </Link>
      )}
    </VStack>
  ) : (
    <div></div>
  );

  const handleSave = () => {
    localStorage.removeItem('budget');
    navigate('/my-plans');
  };

  const handleDeleteClick = () => {
    onDeleteModalOpen()
  }

  const handleConfirmDelete = async () => {
    // Add logic to handle the deletion
    const plan_id = localStorage.getItem('plan_id');
    try {
      const response = await userService.deletePlace(plan_id, currentDay.day_id, places[currentPlace].id);
      toast({
        title: 'Attraction Deleted Successfully',
        description: `Attraction ${currentPlace + 1} was deleted`,
        status: 'success',
        duration: 9000,
        isClosable: true,
      });
    } catch {
      toast({
        title: 'Attraction Selection Unsuccessful',
        description: 'Some Error occurred. Please try again',
        status: 'error',
        duration: 9000,
        isClosable: true,
      });
    }
    onDeleteModalClose();
    getPlacesForDay(currentDay.day_id)
  };

  const handleCancelDelete = () => {
    onDeleteModalClose();
    // Add any additional logic if needed
  };

  const handleEditPLan = () => {
    navigate('/my-places')
  }

  return (
    <VStack
      width="90%"
      spacing={2}
      alignItems="center"
      justifyContent="center"
      m={5}
    >
      <LocationMapModal isOpen={isOpen} onClose={onClose} places={places} />
      <DeleteModal isOpen={isDeleteModalOpen} onClose={onDeleteModalClose} handleCancelDelete={handleCancelDelete} handleConfirmDelete={handleConfirmDelete} />
      <HStack width="100%">
        <VStack
          width="100%"
          spacing={2}
          alignItems="center"
          justifyContent="center"
        >
          <Heading width="100%" fontWeight="600">
            your trip to:
          </Heading>
          <Heading width="100%" fontWeight="700" fontSize="50">
            {selectedDestinationCity}
          </Heading>
        </VStack>
        
        <VStack justifyContent={'flex-end'} alignItems={'flex-end'}>
          <HStack>
            {/* Add the "Spots" and "Dining" buttons here */}
            <Button width="auto" colorScheme="purple" onClick={handleSpots}>
              Spots
            </Button>
            <Button width="auto" colorScheme="purple" onClick={handleDining}>
              Dining
            </Button>
          </HStack>
          <Button width="auto" colorScheme="purple" onClick={handleEditPLan}>
            Edit Plan
          </Button>
        </VStack>
      </HStack>

      <HStack
        style={{ alignItems: 'center', justifyContent: 'space-between' }}
        width="100%"
        spacing={5}
        mb={20}
      >
        <Button // Wrap the icon in a Button
          w={100} // Adjust the width as needed
          h={12} // Adjust the height as needed
          color="black" // Set the color to black
          variant="ghost" // Use "ghost" variant to make it transparent
          onClick={handleLocationMarkerClick} // Add the click handler if needed
        >
          <Icon as={FaMapMarkerAlt} w={10} h={10} color="purple" />{' '}
          {/* Use color="inherit" to maintain black color */}
        </Button>
        <Box display="flex" alignItems="center">
          <Button onClick={handleBackDayClick}>
            <Icon as={ChevronLeftIcon} boxSize={10} />
          </Button>
          <Heading as="h1" size="xl" width="100%" textAlign="center">
            Day {currentDay.day_number + 1}
          </Heading>
          <Button onClick={handleNextDayClick}>
            <Icon as={ChevronRightIcon} boxSize={10} />
          </Button>
        </Box>
        <Box display="flex" alignItems="center" w={100}>
          {' '}
          {/* Use Box to contain "where am I staying?" */}
          <NavLink
            to="/generate-trip/hotels"
            style={{ display: 'flex', alignItems: 'center' }}
          >
            <Text fontSize="xl" fontWeight="bold">
              Where am I staying?
            </Text>
          </NavLink>
        </Box>
      </HStack>

      {places.length === 0 ? (
        <Oval
          height={80}
          width={80}
          color="#4fa94d"
          secondaryColor="#4fa94d"
          strokeWidth={2}
          strokeWidthSecondary={2}
        />
      ) : currentPlace < places.length ? (
        <HStack style={{ width: '70%' }}>
          <Button onClick={handleBackPlaceClick}>
            <Icon as={ChevronLeftIcon} boxSize={10} />
          </Button>
          <Flashcard
            className="flashcard-container"
            width="100%"
            frontCardStyle={{ height: 400 }}
            frontHTML={frontPart}
            backCardStyle={{ minHeight: '400px', overflowY: 'auto' }}
            backHTML={backPart}
          />
          <Button onClick={handleNextPlaceClick}>
            <Icon as={ChevronRightIcon} boxSize={10} />
          </Button>
        </HStack>
      ) : (
        <p>All places exhausted</p>
      )}
      <HStack mt={20} spacing={5} justify="space-between" width="100%">
        <NavLink to="/generate-trip/flights">
          <Button colorScheme="purple">Back to flights</Button>
        </NavLink>
        <Button mt={5} onClick={handleDeleteClick} colorScheme="red">
          <DeleteIcon boxSize={6} />
        </Button>
        <Button colorScheme="purple" onClick={handleSave}>
          Save
        </Button>
      </HStack>
    </VStack>
  );
};

export default Days;
