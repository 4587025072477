import React, { useState, useEffect } from 'react';
import {
  SimpleGrid,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Text,
  Image,
  Center,
  Heading,
} from '@chakra-ui/react';
import { Oval } from 'react-loader-spinner';
import Rating from '../sections/Rating';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { newPlacesAdded, selectNewPlaces } from '../slices/newPlacesSlice';

const CardsPlan = props => {
  const { cP } = useParams();
  const [loading, setLoading] = useState(true);
  const newPlaces = useSelector(selectNewPlaces);

  useEffect(() => {
    if (props.places.length > 0) {
      // Set loading to false once new data is available
      setLoading(false);
    }
  }, [props.places]);

  const truncateDescription = description => {
    if (description !== null) {
      return description.length > 100
        ? `${description.slice(0, 100)}...`
        : description;
    } else {
      return 'No description';
    }
  };

  if (loading) {
    // Show loader while data is being fetched
    return (
      <Center>
        <Oval
          height={80}
          width={80}
          color="#4fa94d"
          secondaryColor="#4fa94d"
          strokeWidth={2}
          strokeWidthSecondary={2}
        />
      </Center>
    );
  }

  // Show the correct data once loading is complete
  return (
    <SimpleGrid spacing={4} columns={{ base: 1, sm: 2, md: 3 }}>
      {props.places.map((place, index) => (
        <Card key={place.id}>
          <CardHeader>
            <Center>
              <Link
                to={{ pathname: `/my-plans/preview/${cP}/spot/${index}` }}
              >
                <Image
                  src={place.image}
                  alt={place.name}
                  height={300}
                  objectFit="cover"
                />
              </Link>
            </Center>
          </CardHeader>
          <CardBody>
            <Link
              to={{ pathname: `/my-plans/preview/${cP}/spot/${index}` }}
            >
              <Heading as="h3" size="md" fontWeight="bold" mb={5}>
                {place.name}
              </Heading>
            </Link>
            <Text>{truncateDescription(place.description)}</Text>
          </CardBody>
          <CardFooter>
            <Rating numReviews={place.num_reviews} rating={place.rating} />
          </CardFooter>
        </Card>
      ))}
    </SimpleGrid>
  );
};

export default CardsPlan;
