import React, { useState, useEffect, useCallback, forwardRef } from 'react';
import {
  VStack,
  Heading,
  Icon,
  Button,
  HStack,
  Text,
  Image,
  Link,
  Flex,
  Badge,
  Box,
  Img,
  useToast,
} from '@chakra-ui/react';
import { ChevronLeftIcon, ChevronRightIcon } from '@chakra-ui/icons';
import userService from '../services/user.service';
import { Flashcard } from 'react-quizlet-flashcard';
import './style.css';
import Rating from '../sections/Rating';
import { useDispatch, useSelector } from 'react-redux';
import { dayAdded, selectDay } from '../slices/daySlice';

import { selectPlaces, placesAdded } from '../slices/placeSlice';
import { selectNewPlaces, newPlacesAdded } from '../slices/newPlacesSlice';

import { NavLink, useLocation, useNavigate, useParams } from 'react-router-dom';
import { Oval } from 'react-loader-spinner';
import styles from '../styles/index.module.scss';
import { FaMapMarkerAlt } from 'react-icons/fa';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
} from '@chakra-ui/react';
import MapPlaces from '../sections/Map_places';
import MapNew from '../sections/MapNew';
import FlashCard from './flashCard';
import MarkerIcon from '../assets/building.png';

export const LocationMapModal = ({ isOpen, onClose, place }) => {
  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose} size="5xl">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Map</ModalHeader>
          <ModalCloseButton />
          <ModalBody width="100%">
            {place && (
              <MapNew
                restLatitude={place.latitude}
                restLongitude={place.longitude}
                MarkerIcon={MarkerIcon}
              />
            )}
          </ModalBody>

          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={onClose}>
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

const Spot = props => {
  const plan_id = localStorage.getItem('plan_id');
  const { isOpen, onOpen, onClose } = useDisclosure();

  const { cP, index } = useParams();
  const [currentPlace, setCurrentPlace] = useState(0);
  const navigate = useNavigate();
  const currentDay = useSelector(selectDay);

  const dispatch = useDispatch();
  const places = useSelector(selectPlaces);
  const newPlaces = useSelector(selectNewPlaces);
  const [neoPlaces, setNeoPlaces] = useState([]);
  const toast = useToast()

  const location = useLocation()
  const currentPath = location.pathname;
  const isGenerateTrip = currentPath.includes('generate-trip');
  const isMyPlans = currentPath.includes('my-plans');
  const selectedDestinationCity = isGenerateTrip ? localStorage.getItem('selectedDestinationCity') : localStorage.getItem('plan_name')

  // const getPlaces = async () => {
  //   if (places.length > 0) {
  //     const data = {
  //       // Ahmed - getting the longitude and latitude of the currentPlace
  //       latitude: places[cP].latitude,
  //       longitude: places[cP].longitude,
  //     };
  //     const response = await userService.getNewPlaces(data);
  //     setNeoPlaces(response.data);
  //     dispatch(newPlacesAdded(response.data));
  //   }
  // };

  // useEffect(() => {
  //   getPlaces();
  // }, [currentDay]);

  // const handleNextPlaceClick = () => {
  //   if (currentPlace < newPlaces.length - 1) {
  //     setCurrentPlace(currentPlace + 1);
  //   }
  // };

  // const handleBackPlaceClick = () => {
  //   if (currentPlace > 0) {
  //     setCurrentPlace(currentPlace - 1);
  //   }
  // };

  const handleLocationMarkerClick = () => {
    onOpen();
  };

  // const handleDining = () => {
  //   navigate('/generate-trip/restaurants')
  // }

  const handleSave = async () => {
    const day_id = currentDay.day_id;
    const data = { ...newPlaces[index], plan: plan_id, day: day_id };
    const response = await userService.addPlace(plan_id, day_id, data);
    toast({
      title: 'Attraction Added Successfully',
      description: `Attraction was added to you plan of Day ${currentDay.day_number + 1}`,
      status: 'success',
      duration: 9000,
      isClosable: true,
    });
    console.log(response.data);
  };

  const handleBackToPreview = () => {
    if (isGenerateTrip) {
      navigate(`/generate-trip/preview/${cP}`);
    } else if (isMyPlans) {
      navigate(`/my-plans/preview/${cP}`);
    }
  };

  return (
    isMyPlans ?
    (<VStack spacing={1} width='100%'>
      <VStack
        width="90%"
        spacing={2}
        alignItems="center"
        justifyContent="center"
        m={5}
      >
        <LocationMapModal
          isOpen={isOpen}
          onClose={onClose}
          place={newPlaces[index]}
        />
        <HStack
          style={{ alignItems: 'center', justifyContent: 'space-between' }}
          width="100%"
          spacing={5}
        >
          <VStack spacing={2} alignItems="center" justifyContent="center">
            <Heading fontWeight="600">your trip to:</Heading>
            <Heading fontWeight="700" fontSize="50">
              {selectedDestinationCity}
            </Heading>
          </VStack>
          <Heading as="h1" size="xl" textAlign="center" fontWeight="600" width={'100%'}>
            Recommended Place
          </Heading>
        </HStack>

        <HStack
          style={{ alignItems: 'center', justifyContent: 'space-between' }}
          width="100%"
          spacing={5}
          mb={5}
        >
          <Button // Wrap the icon in a Button
            w={100} // Adjust the width as needed
            h={12} // Adjust the height as needed
            color="black" // Set the color to black
            variant="ghost" // Use "ghost" variant to make it transparent
            onClick={handleLocationMarkerClick} // Add the click handler if needed
          >
            <Icon as={FaMapMarkerAlt} w={10} h={10} color="purple" />{' '}
            {/* Use color="inherit" to maintain black color */}
          </Button>
        </HStack>

        {/* {newPlaces.length == 0 ? (
          <Oval
            height={80}
            width={80}
            color="#4fa94d"
            secondaryColor="#4fa94d"
            strokeWidth={2}
            strokeWidthSecondary={2}
          />
        ) : ( */}
        <>
          <FlashCard place={newPlaces[index]} />
        </>

        <HStack mt={20} spacing={5} justify="space-between" width="100%">
          <Button colorScheme="purple" onClick={handleBackToPreview}>
            Back to Previews
          </Button>
          <Button colorScheme="purple" onClick={handleSave}>
            Select
          </Button>
        </HStack>
      </VStack>
    </VStack>)
    :
    (<VStack
      width="90%"
      spacing={2}
      alignItems="center"
      justifyContent="center"
      m={5}
    >
      <LocationMapModal
        isOpen={isOpen}
        onClose={onClose}
        place={newPlaces[index]}
      />
      <HStack
        style={{ alignItems: 'center', justifyContent: 'space-between' }}
        width="100%"
        spacing={5}
      >
        <VStack spacing={2} alignItems="center" justifyContent="center">
          <Heading fontWeight="600">your trip to:</Heading>
          <Heading fontWeight="700" fontSize="50">
            {selectedDestinationCity}
          </Heading>
        </VStack>
        <Heading as="h1" size="xl" textAlign="center" fontWeight="600" width={'100%'}>
          Recommended Place
        </Heading>
      </HStack>

      <HStack
        style={{ alignItems: 'center', justifyContent: 'space-between' }}
        width="100%"
        spacing={5}
        mb={5}
      >
        <Button // Wrap the icon in a Button
          w={100} // Adjust the width as needed
          h={12} // Adjust the height as needed
          color="black" // Set the color to black
          variant="ghost" // Use "ghost" variant to make it transparent
          onClick={handleLocationMarkerClick} // Add the click handler if needed
        >
          <Icon as={FaMapMarkerAlt} w={10} h={10} color="purple" />{' '}
          {/* Use color="inherit" to maintain black color */}
        </Button>
      </HStack>

      {/* {newPlaces.length == 0 ? (
        <Oval
          height={80}
          width={80}
          color="#4fa94d"
          secondaryColor="#4fa94d"
          strokeWidth={2}
          strokeWidthSecondary={2}
        />
      ) : ( */}
      <>
        <FlashCard place={newPlaces[index]} />
      </>

      <HStack mt={20} spacing={5} justify="space-between" width="100%">
        <Button colorScheme="purple" onClick={handleBackToPreview}>
          Back to Previews
        </Button>
        <Button colorScheme="purple" onClick={handleSave}>
          Select
        </Button>
      </HStack>
    </VStack>)
  );
};

export default Spot;
