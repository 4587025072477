import React, { useState, useEffect } from 'react';
import userService from '../services/user.service';
import { Oval } from 'react-loader-spinner';
import { Box, VStack, Heading, Grid, Flex, Button, HStack } from '@chakra-ui/react';
import PlanFlight from '../sections/PlanFlight';
import PlanHotel from '../sections/PlanHotel';
import PlanRestaurant from '../sections/PlanRestaurant';
import styles from '../styles/index.module.scss';
import { NavLink } from 'react-router-dom';

const PlanFlights = () => {
  const [loading, setLoading] = useState(true);
  const [flights, setFlights] = useState([]);
  const [hotels, setHotels] = useState([]);
  const [restaurants, setRestaurants] = useState([])
  const plan_id = localStorage.getItem('plan_id');

  const getRestaurantsData = async () => {
    const response = await userService.getRestaurants(plan_id);
    setRestaurants(response?.data);
  }

  const getFlightsData = async () => {
    const response = await userService.getFlights(plan_id);
    setFlights(response?.data);
  };

  const getHotelsData = async () => {
    try {
      const response = await userService.getHotels(plan_id);
      setHotels(response?.data);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getFlightsData();
    getHotelsData();
    getRestaurantsData();
  }, []);

  return (
    <Box p={5}>
      {loading && (
        <Oval
          height={80}
          width={80}
          color="#4fa94d"
          secondaryColor="#4fa94d"
          strokeWidth={2}
          strokeWidthSecondary={2}
        />
      )}

      <VStack align="start" spacing={5} mx={10}>
        <HStack mt={5} spacing={5} justify="space-between" width="100%">
          <Heading as="h2" size="lg">
            Your Selected Flights
          </Heading>
          <div>
      <NavLink to='/my-places'><Button colorScheme='purple' mr={2}>Edit Plan</Button></NavLink>
      <NavLink to='/my-plans/plan-days'><Button colorScheme='purple'>See Full Plan</Button></NavLink>
    </div>
        </HStack>
        <Grid
          templateColumns={{ base: '1fr', md: 'repeat(2, 1fr)' }}
          gap={5}
        >
          {flights.map((flight, index) => (
            <PlanFlight
              key={index}
              flight={flight}
            />
          ))}
        </Grid>

        <Heading as="h2" size="lg">
          Your Selected Hotels
        </Heading>
        <div className={styles.myPlansContainer}>
            <Flex
                justifyContent={'space-around'}
                gap={100}
                flexWrap={'wrap'}
                align="center"
            >
            {hotels.map((hotel, index) => (
                <PlanHotel key={index} hotel={hotel} />
            ))}
            </Flex>
        </div>

        <Heading as="h2" size="lg">
          Your Selected Restaurants
        </Heading>
        <div className={styles.myPlansContainer}>
            <Flex
                justifyContent={'space-around'}
                gap={100}
                flexWrap={'wrap'}
                align="center"
            >
            {restaurants.map((restaurant, index) => (
                <PlanRestaurant key={index} restaurant={restaurant} />
            ))}
            </Flex>
        </div>
      </VStack>
    </Box>
  );
};

export default PlanFlights;
