import React, { useState, useEffect } from 'react';
import {
  VStack,
  HStack,
  Button,
  Heading,
  Flex,
  Text,
  Tab,
  Tabs,
  TabList,
  TabPanels,
  TabPanel,
  Center,
  Grid,
  GridItem,
  Icon,
} from '@chakra-ui/react';
import userService from '../services/user.service';
import Hotel from '../sections/Hotel';
import { useNavigate } from 'react-router-dom';
import { Oval } from 'react-loader-spinner';
import { ChevronUpIcon } from '@chakra-ui/icons'; // Import the up arrow icon

export default function Hotels() {
  const navigate = useNavigate();
  const selectedCities = JSON.parse(localStorage.getItem('selectedCities'));
  const firstCityIataCode = localStorage.getItem('destinationIataCode');
  const firstCityName = localStorage.getItem('selectedDestinationCity');
  const [showScroll, setShowScroll] = useState(false);
  const [hotels, setHotels] = useState({});
  const [loading, setLoading] = useState(true);

  const fetchData = async (iataCode) => {
    try {
      const listHotels = {
        destinationIataCode: iataCode,
        selectedStartDate: localStorage.getItem('selectedStartDate'),
      };
      const response_hotels = await userService.listHotels(listHotels);
      return response_hotels.data;
    } catch (error) {
      console.error('Error fetching hotel data:', error);
      return null;
    }
  };

  const initializeData = async () => {
    const dataPromises = [fetchData(firstCityIataCode)]; // Fetch data for the first city

    selectedCities.forEach((city) => {
      dataPromises.push(fetchData(city.value));
    });

    const cityData = await Promise.all(dataPromises);
    const cityHotelData = {};

    cityData.forEach((data, index) => {
      if (index === 0) {
        // Data for the first city
        cityHotelData[firstCityIataCode] = data;
      } else {
        cityHotelData[selectedCities[index - 1].value] = data;
      }
    });

    setHotels(cityHotelData);
    setLoading(false);
  };

  const handleScroll = () => {
    if (window.scrollY > 300) {
      setShowScroll(true);
    } else {
      setShowScroll(false);
    }
  };

  // Function to scroll to the top of the page
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  useEffect(() => {
    initializeData();
    window.addEventListener('scroll', handleScroll);

    // Cleanup event listener on unmount
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <VStack width="100%" spacing={10} m={5}>
      <HStack width="90%" justifyContent="space-between" alignItems="center">
        {showScroll && (
          <Button
            colorScheme="purple"
            position="fixed"
            right="2rem"
            bottom="2rem"
            onClick={scrollToTop}
            zIndex={5}
          >
            <Icon as={ChevronUpIcon} w={8} h={8} /> {/* Add the up arrow icon */}
          </Button>
        )}
        <Button
          colorScheme="purple"
          aria-label="Back Button"
          onClick={() => {
            navigate('/generate-trip/days');
          }}
        >
          Back to Days
        </Button>
        <Heading textAlign="center" as="h1" size="xl" width="100%">
            Select your Hotel:
        </Heading>
      </HStack>

      <Tabs
        isLazy
        width="90%"
        defaultIndex={0}
        isFitted
        align="center"
        variant="soft-rounded"
      >
        <TabList>
          <Tab>{firstCityName}</Tab>
          {selectedCities && selectedCities.length > 0 && selectedCities.map((city) => (
            <Tab key={city.value}>{city.label}</Tab>
          ))}
        </TabList>
        <TabPanels>
          <TabPanel>
            {loading ? (
              <Center>
                <Oval
                  height={80}
                  width={80}
                  color="#4fa94d"
                  secondaryColor="#4fa94d"
                  strokeWidth={2}
                  strokeWidthSecondary={2}
                />
              </Center>
            ) : hotels[firstCityIataCode] ? (
              <Grid
                templateColumns={{
                  base: '1fr',
                  md: 'repeat(2, 1fr)',
                  lg: 'repeat(3, 1fr)',
                }}
                gap={4}
              >
                {hotels[firstCityIataCode].map((hotel) => (
                  <GridItem key={hotel.hotelId}>
                    <Hotel hotel={hotel} />
                  </GridItem>
                ))}
              </Grid>
            ) : (
              <Heading as="h4">No Hotels available for {firstCityName}</Heading>
            )}
          </TabPanel>
          {selectedCities && selectedCities.length > 0 && selectedCities.map((city) => (
            <TabPanel key={city.value}>
              {loading ? (
                <Center>
                  <Oval
                    height={80}
                    width={80}
                    color="#4fa94d"
                    secondaryColor="#4fa94d"
                    strokeWidth={2}
                    strokeWidthSecondary={2}
                  />
                </Center>
              ) : hotels[city.value] ? (
                <Grid
                  templateColumns={{
                    base: '1fr',
                    md: 'repeat(2, 1fr)',
                    lg: 'repeat(3, 1fr)',
                  }}
                  gap={4}
                >
                  {hotels[city.value].map((hotel) => (
                    <GridItem key={hotel.hotelId}>
                      <Hotel hotel={hotel} />
                    </GridItem>
                  ))}
                </Grid>
              ) : (
                <Heading as='h4'>No Hotels available for {city.label}</Heading>
              )}
            </TabPanel>
          ))}
        </TabPanels>
      </Tabs>
    </VStack>
  );
}
