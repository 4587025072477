import React, { useState } from 'react';
import {
  Box,
  Flex,
  Text,
  Badge,
  VStack, HStack, Stack,
  Spacer,
  Icon,
  List, ListItem, UnorderedList,
  Stat, StatLabel, StatNumber, StatGroup,
  Button,
  Collapse,
} from '@chakra-ui/react';
import { MdCheckCircle } from 'react-icons/md'; // Import the check circle icon

function calculateDuration(duration) {
  // Extract hours and minutes from flight_duration using regular expressions
  const pattern = /PT(?:(\d+)H)?(?:(\d+)M)?/;

  const match = duration.match(pattern);

  let hours = match && match[1] ? parseInt(match[1], 10) : 0;
  let minutes = match && match[2] ? parseInt(match[2], 10) : 0;

  // Create a formatted flight duration string
  const formattedFlightDuration = `${hours} Hours ${minutes} Minutes`;

  return formattedFlightDuration;
}


const FlightOffer = ({ flight, isSelected, onFlightSelect }) => {
  // Convert the departure time to a more readable format
  const formattedDepartureTime = new Date(flight.departure_time).toLocaleString();
  const [isSegmentsOpen, setIsSegmentsOpen] = useState(false);

  const handleSegmentsToggle = (event) => {
    event.stopPropagation();
    setIsSegmentsOpen(!isSegmentsOpen);
  };

  // Function to handle flight selection
  const handleFlightClick = () => {
    // Call the onFlightSelect function with the current flight when the flight card is clicked
    if (isSelected) {
      // Deselect the flight by calling onFlightSelect with null
      onFlightSelect(null);
    } else {
      // Select the flight by calling onFlightSelect with the current flight
      onFlightSelect(flight);
    }
  };

  return (
    <VStack
      p={5}
      spacing={5}
      borderWidth={2}
      borderRadius="md"
      sx={{width:'100%'}}
      alignItems={'start'}
      backgroundColor={isSelected ? 'gray.100' : 'white'}
      transition="transform 0.2s, border-color 0.2s, box-shadow 0.2s"
      _hover={!isSelected ? {
        transform: 'scale(1.02)',
        borderColor: 'purple.500',
        boxShadow: 'lg',
        cursor: 'pointer',
      } : {}}
      onClick={handleFlightClick} // Handle flight selection on click
      borderColor={isSelected ? 'purple.500' : 'purple.200'}
    >
      {isSelected && (
        <Icon
          as={MdCheckCircle}
          color="purple.500"
          position="sticky"
          top={2}
          left={2}
          fontSize="24px"
        />
      )}
      <Stack direction={['column', 'row']} width="100%" alignItems={['flex-start', 'center']} justifyContent={['flex-start', 'space-between']}>
        <Badge colorScheme="purple" fontSize="lg" fontWeight="bold">
          AIRLINE: {flight.carrier_code}
        </Badge>
        <HStack
          ml={['0', 'auto']} // Use marginLeft auto on smaller screens
        >
          <Text fontWeight="bold" width="auto">
            Duration:
          </Text>
          <Text width="auto">{`${flight.total_duration}`}</Text>
        </HStack>
      </Stack>

      <Button onClick={handleSegmentsToggle} colorScheme="purple" size="sm" variant={'outline'}>
        Segments
      </Button>

      {isSegmentsOpen && (
    <Box p={4} borderWidth={1} borderRadius="md" width='100%' mt={4}>
      <VStack spacing={3} width='100%'>
        {flight.segments.map((segment, index) => (
          <VStack key={index} alignItems="flex-start" width='100%' spacing={3}>
            <Badge colorScheme="blue">{`Segment ${index + 1}`}</Badge>
            <StatGroup width={'100%'}>
              <Stat>
                <StatLabel>Departure</StatLabel>
                <StatNumber>
                  {`${segment.departure.iataCode} - ${new Date(segment.departure.at).toLocaleTimeString('en-US')}`}
                </StatNumber>
              </Stat>
              <Stat>
                <Flex alignItems="flex-end" justifyContent="flex-end" flexDirection="column" textAlign="end">
                  <StatLabel>Arrival</StatLabel>
                  <StatNumber>
                    {`${segment.arrival.iataCode} - ${new Date(segment.arrival.at).toLocaleTimeString('en-US')}`}
                  </StatNumber>
                </Flex>
              </Stat>
            </StatGroup>
            <HStack>
              <Text fontWeight="bold">Aircraft:</Text>
              <Text>{segment.aircraft.code}</Text>
            </HStack>
            <Stack
              direction={['column', 'row']}
              width="100%"
              justifyContent={['flex-start', 'space-between']}
              alignItems={['flex-start', 'center']}
            >
              <HStack>
                <Text fontWeight="bold">Duration:</Text>
                <Text>{calculateDuration(segment.duration)}</Text>
              </HStack>
              <HStack>
                <Text fontWeight="bold">Cabin:</Text>
                <Text>{flight.cabin_of_each_segment[index]}</Text>
              </HStack>
            </Stack>
          </VStack>
        ))}
      </VStack>
    </Box>
  )}

      <StatGroup width={'100%'}>
        <Stat>
          <StatLabel>Price</StatLabel>
          <StatNumber>{flight.total_price}</StatNumber>
        </Stat>

        <Stat>
          <Flex alignItems="flex-end" justifyContent="flex-end" flexDirection="column" textAlign="end">
            <StatLabel>Bookable Seats</StatLabel>
            <StatNumber>{flight.number_of_bookable_seats}</StatNumber>
          </Flex>
        </Stat>
      </StatGroup>
    </VStack>
  );
};

export default FlightOffer;
