import React, { useState, useEffect } from 'react';
import {
  VStack,
  HStack,
  Stack,
  Button,
  Heading,
  Flex,
  Text,
  Center,
  Grid,
  GridItem,
  Icon,
  Box,
  Select,
  FormControl, 
  FormLabel,
} from '@chakra-ui/react';
import userService from '../services/user.service';
import Restaurant from '../sections/Restaurant';
import { useNavigate, NavLink } from 'react-router-dom';
import { Oval } from 'react-loader-spinner';
import { ChevronUpIcon } from '@chakra-ui/icons'; // Import the up arrow icon
import { useSelector } from 'react-redux';
import { selectDay } from '../slices/daySlice';

export default function Dining() {
  const navigate = useNavigate();
  const [showScroll, setShowScroll] = useState(false);
  const [restaurants, setRestaurants] = useState({});
  const [loading, setLoading] = useState(false);
  const [places, setPlaces] = useState([])
  const currentDay = useSelector(selectDay)
  const plan_id = localStorage.getItem('plan_id')
  const [selectedPlace, setSelectedPlace] = useState(''); // Set the initial value to the first place's name


  const [selectedOption, setSelectedOption] = useState('');
  const foodOptions = ["British", "Indian", "Italian", "Mexican", "Japanese", "Chinese"];

  const getPlacesForDay = async () => {
    const response = await userService.getPlaces(plan_id, currentDay.day_id);
    setPlaces(response.data);
  };

  const fetchRestaurants = async () => {
    if (places.length === 0) {
      // Handle the case when there are no places available.
      return;
    }
  
    const selectedPlaceData = places.find((place) => place.name === selectedPlace);

    if (!selectedPlaceData) {
      // Handle the case when the selected place is not found in the places array.
      return;
    }

    const data = {
      latitude: selectedPlaceData.latitude || 0,
      longitude: selectedPlaceData.longitude || 0,
      food_type: selectedOption,
    };
  
    // Find a place with valid latitude and longitude.
    for (let i = 0; i < places.length; i++) {
      if (places[i].latitude && places[i].longitude) {
        data.latitude = places[i].latitude;
        data.longitude = places[i].longitude;
        break;
      }
    }

    const response = await userService.listRestaurants(data);
    console.log(response.data)
    setRestaurants(response.data)
    if (response){
      setLoading(false)
    }
  }

  const handleGenerateClick = async () => {
    setLoading(true)
    await fetchRestaurants()
  };

  const handleScroll = () => {
    if (window.scrollY > 300) {
      setShowScroll(true);
    } else {
      setShowScroll(false);
    }
  };

  // Function to scroll to the top of the page
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };
  

  useEffect(() => {
    getPlacesForDay()

    window.addEventListener('scroll', handleScroll);

    // Cleanup event listener on unmount
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  // useEffect(() => {
  //   fetchRestaurants()
  // }, [places])

  return (
    <VStack width="100%" spacing={10} m={5}>
      {showScroll && (
          <Button
            colorScheme="purple"
            position="fixed"
            right="2rem"
            bottom="2rem"
            onClick={scrollToTop}
            zIndex={5}
          >
            <Icon as={ChevronUpIcon} w={8} h={8} /> {/* Add the up arrow icon */}
          </Button>
        )}
      <HStack style={{ alignItems: 'center', justifyContent: 'space-between' }} width='90%' spacing={5}>
        <Button
          colorScheme="purple"
          aria-label="Back Button"
          onClick={() => {
            navigate('/generate-trip/days');
          }}
        >
          Back to Days
        </Button>
        <Heading textAlign="center" as="h1" size="lg" width="100%">
            Select Restaurant:
        </Heading>
        <Box display="flex" alignItems="center" maxW={110} w='100%'> 
        </Box>
      </HStack>

      <Stack
        spacing={4}
        direction={{ base: 'column', md: 'row' }} // Stack direction based on screen size
        alignItems={'end'}
      >
        <FormControl>
          <FormLabel htmlFor="foodType">Food Type</FormLabel>
          <Select
            id="foodType"
            placeholder="All"
            value={selectedOption}
            onChange={(e) => setSelectedOption(e.target.value)}
          >
            {foodOptions.map((option) => (
              <option key={option} value={option}>
                {option}
              </option>
            ))}
          </Select>
        </FormControl>

        <FormControl>
          <FormLabel htmlFor="restaurantLocation">Restaurants near to</FormLabel>
          <Select
            id="restaurantLocation"
            placeholder="Select Place"
            value={selectedPlace}
            onChange={(e) => setSelectedPlace(e.target.value)}
          >
            {places.map((place, index) => (
              <option key={index} value={place.name}>
                {place.name}
              </option>
            ))}
          </Select>
        </FormControl>
        <Button
          colorScheme="purple"
          onClick={handleGenerateClick}
          w={200}
          isDisabled={!selectedPlace}
        >
          Generate
        </Button>
      </Stack>

      {loading ? (
        <Center>
          <Oval
            height={80}
            width={80}
            color="#4fa94d"
            secondaryColor="#4fa94d"
            strokeWidth={2}
            strokeWidthSecondary={2}
          />
        </Center>
      ) : restaurants && restaurants.length > 0 ? (
        <>
          <Grid
            templateColumns={{
              base: '1fr',
              md: 'repeat(2, 1fr)',
              lg: 'repeat(3, 1fr)',
            }}
            gap={4}
          >
            {restaurants.map((restaurant) => (
              <GridItem key={restaurant.location_id}>
                <Restaurant restaurant={restaurant} places={places}/>
              </GridItem>
            ))}
          </Grid>
        </>
      ) : (
        <Heading as="h4">No Restaurants available</Heading>
      )}
    </VStack>
  );
}

