import React, { useState } from 'react';
import {
  Box,
  Text,
  Button,
  useColorModeValue,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Image,
  Stack,
  VStack, Flex, useToast, Center,
  Heading,
  Stat, StatLabel, StatNumber, 
  Badge,
  HStack,
} from '@chakra-ui/react';
import userService from '../services/user.service';
import { useDispatch, useSelector } from 'react-redux';
import { selectDay } from '../slices/daySlice';
import { Oval } from 'react-loader-spinner';
import { useNavigate } from 'react-router-dom';
import ProgressBar from '@ramonak/react-progress-bar';
import MarkerIcon from '../assets/hotel.png'
import Map from './Map';
import ChakraCarousel from './ChakraCarousel/ChakraCarousel';

export default function Hotel({ hotel }) {
  const colorModeValue = useColorModeValue('white', 'gray.800');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [hotelDetails, setHotelDetails] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [noOffersModal, setNoOffersModal] = useState(false)
  const toast = useToast()
  const navigate = useNavigate()

  const currentDay = useSelector(selectDay)

  const fetchHotelDetails = async () => {
    setIsLoading(true);

    try {
      const data = {
        hotelId: hotel.hotelId, // Use the appropriate key for hotel ID
        name: hotel.name,
        numPeople: localStorage.getItem('numPeople'),
        selectedStartDate: new Date(localStorage.getItem('selectedStartDate')).toISOString().split('T')[0],
        budget: localStorage.getItem('budget'),
      };
      const response = await userService.hotelDetails(data);
      if (response.status == 204) {
        setNoOffersModal(true);
        console.log('No offers found for this hotel currently!')
      }
      else {
        console.log(response.data)
        setHotelDetails(response.data);
      }
    } catch (error) {
      setNoOffersModal(true);
      console.error('Error fetching hotel details:', error);
    } finally {
      setIsLoading(false);
      setIsModalOpen(true);
    }

  };

  const handleOfferSelection = async (description, price,offer_id,offer) => {console.log('OFFER',offer);

    console.log('HOTEL DETAILS',hotelDetails);
      const inputData = {
        name: hotelDetails.name,
        description: description,
        hotel_image: hotelDetails.hotel_image, // Provide the correct image URL
        hotel_price: price,
        overallRating: hotelDetails.overallRating ? hotelDetails.overallRating : null,
        numberOfReviews: hotelDetails.numberOfReviews ? hotelDetails.numberOfReviews : null,
        numberOfRatings: hotelDetails.numberOfRatings ? hotelDetails.numberOfRatings : null,
        latitude: hotelDetails.latitude,
        longitude: hotelDetails.longitude, 
        day: currentDay.day_id,
        day_number: currentDay.day_number + 1,
        offerId:offer_id
        };
      const plan_id = localStorage.getItem('plan_id');
      try {
      const response = await userService.addHotel(inputData, plan_id);
      toast({
          title: 'Hotel Selected Successfully',
          description: `The hotel was saved for Day ${currentDay.day_number + 1}.`,
          status: 'success',
          duration: 9000,
          isClosable: true,
      })
      navigate('/generate-trip/days')
      // navigate('/bookHotel',{ state: { hotelDetails : hotelDetails,price:price,offer_id:offer_id,plan_id:plan_id,description:description } })

      } catch {
      toast({
          title: 'Hotel Selection Unsuccessful',
          description: "Some Error occured. Please try again",
          status: 'error',
          duration: 9000,
          isClosable: true,
      })
      }

      setIsModalOpen(false);
  }

  return (
    <>
      <Box
        maxW={'350px'}
        w={'100%'}
        p={4}
        borderWidth={1}
        borderRadius="lg"
        bg={colorModeValue}
        transition="transform 0.2s"
        _hover={{ transform: 'scale(1.05)' }}
        _active={{ transform: 'scale(0.95)' }}
      >
        <Text fontSize="xl" fontWeight="bold">
          {hotel.name}
        </Text>
        <Button
          size="sm"
          mt={4}
          alignSelf="flex-end"
          colorScheme="purple"
          onClick={fetchHotelDetails}
        >
          See Details
        </Button>
      </Box>

      {hotelDetails && (
        <Modal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} size="6xl">
        <ModalOverlay />
        <ModalContent height="fit-content">
          <Flex flexDirection={{ base: 'column', md: 'row' }} alignItems={{ base: 'center', md: 'initial' }}>
            <Box flex={1} p={4}>
              <ModalHeader>{hotelDetails.name}</ModalHeader>
              <ModalCloseButton />
              <ModalBody >
                <VStack align='start' spacing={1}>
                    <Image src={hotelDetails.hotel_image} alt={hotelDetails.name} />
                    {!hotelDetails.rating ? (
                    <>
                        <Text fontWeight="bold" fontSize="lg">Overall Rating:</Text>
                        <Stack width='100%'><ProgressBar completed={hotelDetails.overallRating} /></Stack>
                        <Text fontWeight="bold" fontSize="lg">Number of Reviews: {hotelDetails.numberOfReviews}</Text>
                        {Object.entries(hotelDetails.sentiments).map(([sentiment, value]) => (
                        <Stack width="100%" spacing={3} key={sentiment} align="start">
                            <Text fontWeight="bold" fontSize="lg">{sentiment}:</Text>
                            <Box width="100%">
                            <ProgressBar completed={Number(value)} style={{ width: '100%' }} />
                            </Box>
                        </Stack>
                        ))}
                    </>
                    ) : null}
                </VStack>
              </ModalBody>
            </Box>
    
            {/* Right half - Map */}
              <ModalBody alignItems='center' justifyContent='center' width='100%' height='100%'>
                <Center flex={1} display={{ base: 'none', md: 'block' }} alignItems='center' justifyContent='center' height='100%' width='100%'>
                  <Map latitude={hotelDetails.latitude} longitude={hotelDetails.longitude} MarkerIcon={MarkerIcon}/>
                </Center>
                <Box maxWidth="sm" p={4}>
                  <Heading size="lg" mb={4}>Available Offers</Heading>
                  <ChakraCarousel gap={32}>
                    {hotelDetails.offers.map(offer => (
                      <Box key={offer.offer_id} borderWidth="2px" borderColor='purple.300' borderRadius="lg" overflow="hidden" p={4} mb={4}>
                        <VStack align="start" spacing={2}>
                          <Text fontWeight="bold" fontSize="xl">Price: {offer.price_currency} {offer.total_price}</Text>
                          <Text>{offer.description}</Text>
                          <HStack><Text fontWeight={'bold'}>Check-in:</Text><Text>{offer.check_in_date}</Text></HStack>
                          <HStack><Text fontWeight={'bold'}>Check-out:</Text><Text>{offer.check_out_date}</Text></HStack>
                        </VStack>

                        {/* Additional Details using Stats */}
                        {offer.bed_type && <Flex mt={4} justify="space-between" align="center">
                          <Stat>
                            <StatLabel>Bed Type</StatLabel>
                            <StatNumber>{offer.bed_type}</StatNumber>
                          </Stat>
                          <Stat>
                            <StatLabel>Beds</StatLabel>
                            <StatNumber>{offer.beds}</StatNumber>
                          </Stat>
                          {/* Add more stats as needed */}
                        </Flex>}

                        {/* Select Button */}
                        <Button mt={4} colorScheme="purple" onClick={() => handleOfferSelection(offer.description, offer.total_price,offer.offer_id,offer)}>
                          Select
                        </Button>
                      </Box>
                    ))}
                  </ChakraCarousel>
                </Box>
              </ModalBody>
          </Flex>
          {/* On smaller screens, show the map below the content */}
          <Box display={{ base: 'block', md: 'none' }}>
            <ModalBody alignItems='center' justifyContent='center' width='100%'>
              <Map latitude={hotelDetails.latitude} longitude={hotelDetails.longitude} MarkerIcon={MarkerIcon} />
            </ModalBody>
          </Box>
        </ModalContent>
      </Modal>

    )}

  {isLoading && (
      <Modal isOpen={isLoading}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Loading</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
          <Flex justifyContent="center" alignItems="center">
            <Oval
              height={80}
              width={80}
              color="#4fa94d"
              secondaryColor="#4fa94d"
              strokeWidth={2}
              strokeWidthSecondary={2}
            />
          </Flex>
          </ModalBody>
        </ModalContent>
      </Modal>
    )}

    {noOffersModal && (
      <Modal isOpen={noOffersModal} onClose={() => setNoOffersModal(false)}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>No Offers Found</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text fontSize="lg" fontWeight="bold">
              No offers found for this hotel currently.
            </Text>
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="purple" onClick={() => setNoOffersModal(false)}>
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    )}

    </>
  );
}
