import React, {useEffect} from 'react';
import {
  Box,
  Heading,
  Flex,
  Divider,
  Grid,
  VStack,
  HStack,
  Button
} from '@chakra-ui/react';
import { Outlet } from 'react-router-dom';
import { useNavigate, useLocation } from 'react-router-dom';

export default function GenerateTrip() {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    // Check if the current location is "/generate-trip"
    if (location.pathname === '/generate-trip') {
      // Navigate to "/generate-trip/flights"
      navigate('/generate-trip/flights');
    }
  }, [location, navigate]);

  return (
    <>
      <VStack spacing={1} width='100%'>
        <Outlet />
      </VStack>
    </>
  );
}

