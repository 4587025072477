import React, { useState } from 'react';
import {
  Box,
  Button,
  FormControl,
  Input,
  VStack,
  Image,
  Heading,
  Alert,
  Text,
  FormErrorMessage
} from '@chakra-ui/react';
import styles from '../styles/index.module.scss';
import cancelIcon from '../assets/cancelIcon.svg';
import Logo from '../assets/logo.png';
import { useFormik } from 'formik';
import { useNavigate, useParams } from 'react-router-dom';
import * as Yup from 'yup';
import AuthService from '../services/auth.service';
import { useAuthContext } from '../contexts/AuthContext';
import CustomModal from '../sections/CustomModal'
import { resetPassSuccess } from '../constants';

export default function Reset_Password_Confirm(){
    const { uid, token } = useParams();
    const [success, setSuccess] = useState(false);
    const navigate = useNavigate()

    const handleSuccess = () => {
        setSuccess(false);
        navigate('/login')
      };

    const validationSchema = Yup.object({
      new_password: Yup.string()
          .min(8, 'Password must be at least 8 characters')
          .matches(
          /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[A-Za-z\d]+$/,
          'Password must contain at least one uppercase letter, one lowercase letter, and one number'
          )
          .required('Password is required'),
      re_new_password: Yup.string()
        .oneOf([Yup.ref('new_password'), null], 'Passwords must match')
        .required('Confirm Password is required'),
    });
  
    const formik = useFormik({
      initialValues: {
        new_password: '',
        re_new_password: '',
      },
      validationSchema,
      onSubmit: async (values) => {
        try {
          const submission = {
            new_password: values.new_password,
            re_new_password: values.re_new_password,
            uid: uid,
            token:token
          }
          const response = await AuthService.resetPasswordConfirm(submission);
          setSuccess(true)

        } catch (error) {
          // Handle registration error here
          console.error('Password error:', error);
        }
      },
    });

    return (
      <Box>
        <Box className={styles.modalWrapper}>
          <VStack spacing={4}>
            <Box
              display={'flex'}
              w={'100%'}
              alignItems={'center'}
              justifyContent={'start'}
              gap={10}
            >
              <Heading fontWeight="500" fontSize="30" width='100%' textAlign='center' >
                Reset password confirm
              </Heading>
            </Box>
            <form style={{width:'100%'}} onSubmit={formik.handleSubmit}>
              <FormControl isInvalid={formik.touched.new_password && formik.errors.new_password}>
                <Input
                  className={styles.inputField}
                  id="new_password"
                  placeholder="New Password"
                  name="new_password"
                  autoComplete="new-password"
                  type='password'
                  value={formik.values.new_password}
                  onChange={formik.handleChange}
                  width="100%"
                  required
                />
                <FormErrorMessage>{formik.errors.new_password}</FormErrorMessage>
              </FormControl>
              <FormControl isInvalid={formik.touched.re_new_password && formik.errors.re_new_password}>
                <Input
                  className={styles.inputField}
                  id="re_new_password"
                  placeholder="Confirm New Password"
                  name="re_new_password"
                  autoComplete="new-password"
                  type='password'
                  value={formik.values.re_new_password}
                  onChange={formik.handleChange}
                  width="100%"
                  required
                />
                <FormErrorMessage>{formik.errors.re_new_password}</FormErrorMessage>
              </FormControl>
              <Box w={'100%'} display={'flex'} justifyContent={'center'}>
                <Button className={styles.saveDeatilsButton} type="submit">
                  Confirm
                </Button>
              </Box>
            </form>
           
          </VStack>

          <CustomModal isOpen={success} onClose={handleSuccess} title='Reset Password Confirm' description={resetPassSuccess}/>
        </Box>
      </Box>
    );
}

// export const resetPasswordConfirmAction = async ({request, params}) => {
//     const data = await request.formData()
//     const {uid, token} = params
//     const submission = {
//       new_password: data.get('new_password'),
//       re_new_password: data.get('re_new_password'),
//       uid: uid,
//       token: token,
//     }
//     // error handling
//     try{
//         const response = await AuthService.resetPasswordConfirm(submission)
//         // console.log(response.data)
//         return redirect('/login');
//     }catch(e){
//         throw e
//     }

// }