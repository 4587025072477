import React, {useState, useEffect} from 'react';
import {
  Box,
  Heading,
  Flex,
  Divider,
  Grid,
  VStack,
  HStack,
  Button,
  useToast,
  Icon,
  Select,
} from '@chakra-ui/react';
import FlightOffer from '../sections/FlightOffer';
import { useNavigate } from 'react-router-dom';
import styles from '../styles/index.module.scss';
import userService from '../services/user.service';
import { Oval } from 'react-loader-spinner';
import { ChevronUpIcon } from '@chakra-ui/icons'; // Import the up arrow icon

export default function Flights() {
  const navigate = useNavigate();
  // const flights = useSelector(selectAllFlights);
  const [flights, setFlights] = useState(null)
  const [flightsDest, setFlightsDest] = useState(null);
  const plan_id = localStorage.getItem('plan_id')
  const [selectedDepartFlight, setSelectedDepartFlight] = useState(null);
  const [selectedReturnFlight, setSelectedReturnFlight] = useState(null);
  const [loading, setLoading] = useState(true);
  const toast = useToast()
  const [showScroll, setShowScroll] = useState(false);
  const [selectedFlightType, setSelectedFlightType] = useState('departure');

  const getDataOrigin = async () => {
    const flightOrigin = {
      date:new Date(localStorage.getItem('selectedStartDate')).toISOString().split('T')[0],
      destinationIataCode:localStorage.getItem('destinationIataCode'),
      originIataCode:localStorage.getItem('originIataCode'),
      numPeople: localStorage.getItem('numPeople'),
      budget: localStorage.getItem('budget'),
    }
    try{
      const response_flightOrigin = await userService.listFlights(flightOrigin)
      if (response_flightOrigin.status == 200){
        setFlights(response_flightOrigin.data)
      }
    } catch {
      console.log('Error Occureed')
    } finally {
      setLoading(false)
    }

  }

  const getDataDest = async () => {
    const flightDest = {
      date:new Date(localStorage.getItem('selectedEndDate')).toISOString().split('T')[0],
      destinationIataCode:localStorage.getItem('originIataCode'),
      originIataCode:localStorage.getItem('destinationIataCode'),
      numPeople: localStorage.getItem('numPeople'),
      budget: localStorage.getItem('budget'),
    }
    try{
      const response_flightDest = await userService.listFlights(flightDest)
      if (response_flightDest.status == 200){
        setFlightsDest(response_flightDest.data)
      }
    } catch {
      console.log('Error Occureed')
    }
  }

  useEffect(() => {
    getDataOrigin()
    getDataDest()
    // Add scroll event listener
    window.addEventListener('scroll', handleScroll);

    // Cleanup event listener on unmount
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [])

  // Function to handle scroll event and show/hide scroll-to-top button
  const handleScroll = () => {
    if (window.scrollY > 300) {
      setShowScroll(true);
    } else {
      setShowScroll(false);
    }
  };

  // Function to scroll to the top of the page
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  const handleFlightDepartSelection = (flight) => {
    setSelectedDepartFlight(flight);
  };

  const handleFlightReturnSelection = (flight) => {
    setSelectedReturnFlight(flight);
  };

  const handleNext = async () => {
    if (selectedDepartFlight){
      try {
        const response = await userService.addFlight({...selectedDepartFlight, type:'Departure'}, plan_id)
        toast({
          title: 'Flights Selected Successfully',
          description: `Your Flights were saved!`,
          status: 'success',
          duration: 9000,
          isClosable: true,
        })
      } catch {
        toast({
          title: 'Flights Selection Unsuccessful',
          description: `Please Try Again.`,
          status: 'error',
          duration: 9000,
          isClosable: true,
        })
      }
    }
    if (selectedReturnFlight){
      const response = await userService.addFlight({...selectedReturnFlight, type:'Return'}, plan_id)
    }
    navigate('/loading', { state: { flights : selectedDepartFlight } });
  };

  const handleBack = () => {
    navigate('/form');
  };

  const selectedDestinationCity = localStorage.getItem('selectedDestinationCity')

  return (
    <>
      <HStack width='90%' spacing={4}>
        <VStack width='100%' spacing={2} alignItems='center' justifyContent='center'>
          <Heading width='100%' fontWeight="600">your trip to:</Heading>
          <Heading width='100%' fontWeight="700" fontSize="50">
            {selectedDestinationCity}
          </Heading>
        </VStack>

        <Button
          colorScheme='purple'
          variant="solid"
          onClick={() => setSelectedFlightType("departure")}
        >
          Departure
        </Button>

        <Button
          colorScheme='purple'
          variant="solid"
          onClick={() => setSelectedFlightType("return")}
        >
          Return
        </Button>
      </HStack>

      <VStack spacing={5} width={'90%'} alignItems='center' justifyContent='center'  m={5}>
        {showScroll && (
          <Button
            colorScheme="purple"
            position="fixed"
            right="2rem"
            bottom="2rem"
            onClick={scrollToTop}
            zIndex={5}
          >
            <Icon as={ChevronUpIcon} w={8} h={8} /> {/* Add the up arrow icon */}
          </Button>
        )}
        {!loading && !flights && !flightsDest && <Heading>No flights available!</Heading>}
        {flights || flightsDest ? (
          <Heading as="h1" size="xl" width='100%'>
            Select your Flight:
          </Heading>
        ) : (
          <></>
        )}
        <Box w={'100%'} mb={5} display={'flex'} justifyContent={'center'}>
          <Button onClick={handleNext} className={styles.saveDeatilsButton}>
            Finish flight selection
          </Button>
        </Box>
        {selectedFlightType == 'departure' ? 
          <VStack
            width='100%'
            gap={5}
          >
            {flights && flights.map((flight, index) => (
              <FlightOffer
                key={index}
                flight={flight}
                isSelected={flight === selectedDepartFlight}
                onFlightSelect={handleFlightDepartSelection}
              />
            ))}
          </VStack>
          :
          <VStack
            width='100%'
            gap={5}
          >
            {flightsDest && flightsDest.map((flight, index) => (
              <FlightOffer
                key={index}
                flight={flight}
                isSelected={flight === selectedReturnFlight}
                onFlightSelect={handleFlightReturnSelection}
              />
            ))}
          </VStack>
        }

        {loading && 
          <Oval
          height={80}
          width={80}
          color="#4fa94d"
          secondaryColor="#4fa94d"
          strokeWidth={2}
          strokeWidthSecondary={2}
        />
        }
      </VStack>
    </>
  );
}
