import { useState } from 'react';
import { Box, FormControl, FormLabel, Input, Select, Button, VStack, HStack } from '@chakra-ui/react';

const TravelerForm = () => {
  const [travelerData, setTravelerData] = useState({
    id: "1",
    dateOfBirth: "1982-01-16",
    name: { firstName: "JORGE", lastName: "GONZALES" },
    gender: "MALE",
    contact: {
      emailAddress: "jorge.gonzales833@telefonica.es",
      phones: [
        {
          deviceType: "MOBILE",
          countryCallingCode: "34",
          number: "480080076",
        }
      ],
    },
    documents: [
      {
        documentType: "PASSPORT",
        birthPlace: "Madrid",
        issuanceLocation: "Madrid",
        issuanceDate: "2015-04-14",
        number: "00000000",
        expiryDate: "2025-04-14",
        issuanceCountry: "ES",
        validityCountry: "ES",
        nationality: "ES",
        holder: true,
      }
    ],
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setTravelerData(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Handle form submission logic here
  };

  return (
    <Box p={4}>
      <form onSubmit={handleSubmit}>
        <VStack spacing={4} align="stretch">
          <FormControl id="firstName">
            <FormLabel>First Name</FormLabel>
            <Input type="text" name="name.firstName" value={travelerData.name.firstName} onChange={handleChange} />
          </FormControl>
          <FormControl id="lastName">
            <FormLabel>Last Name</FormLabel>
            <Input type="text" name="name.lastName" value={travelerData.name.lastName} onChange={handleChange} />
          </FormControl>
          <FormControl id="dateOfBirth">
            <FormLabel>Date of Birth</FormLabel>
            <Input type="date" name="dateOfBirth" value={travelerData.dateOfBirth} onChange={handleChange} />
          </FormControl>
          <FormControl id="gender">
            <FormLabel>Gender</FormLabel>
            <Select name="gender" value={travelerData.gender} onChange={handleChange}>
              <option value="MALE">Male</option>
              <option value="FEMALE">Female</option>
              <option value="OTHER">Other</option>
            </Select>
          </FormControl>
          <FormControl id="emailAddress">
            <FormLabel>Email Address</FormLabel>
            <Input type="email" name="contact.emailAddress" value={travelerData.contact.emailAddress} onChange={handleChange} />
          </FormControl>
          <FormControl id="phoneNumber">
            <FormLabel>Phone Number</FormLabel>
            <Input type="tel" name="contact.phones[0].number" value={travelerData.contact.phones[0].number} onChange={handleChange} />
          </FormControl>
          <HStack spacing={4}>
            <Button colorScheme="blue" type="submit">Submit</Button>
            <Button colorScheme="gray">Cancel</Button>
          </HStack>
        </VStack>
      </form>
    </Box>
  );
};

export default TravelerForm;
