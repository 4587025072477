import styles from "../styles/index.module.scss";
import React from "react";
import { 
  Box, 
  Flex, 
  Link, 
  Image, 
  IconButton,
  HStack,
  Stack,
  VStack,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  MenuDivider,
  Button,
  useColorModeValue,
  useDisclosure, } from "@chakra-ui/react";
import { Link as RouterLink, useLocation, useNavigate } from "react-router-dom"; // Import the necessary components and hooks
import AuthService from "../services/auth.service";
import logo from "../assets/logo.png";
import { HamburgerIcon, CloseIcon } from '@chakra-ui/icons'
import { useAuthContext } from "../contexts/AuthContext";



export default function Navbar() {
  const location = useLocation(); // Use the useLocation hook to get the current location
  const { isOpen, onOpen, onClose } = useDisclosure()
  const navigate = useNavigate()
  const {user, updateAuth} = useAuthContext()

  const handleLogout = () => {
    AuthService.logout(); 
    updateAuth();
    navigate('/')
  }

  const MenuLink = ({ link, navigate }) => {
    return (
      <RouterLink to={link.href}>
        <Box
          width="100%"
          textAlign="center"
          as="a"
          px={2}
          py={1}
          rounded="md"
          _hover={{
            textDecoration: 'none',
            bg: useColorModeValue('gray.200', 'gray.700'),
          }}
        >
          {link.text}
        </Box>
      </RouterLink>
    );
  };

  const links = [
    { text: "Home", href: user ? "/form" : "/" }, // Conditionally set the "href"
    { text: "Generate Trip", href: "/generate-trip", isDisabled: true },
    { text: "My Plans", href: "/my-plans" },

    { text: "My Account", href: "/useraccount" },
  ];

  const menuLinks = [
    { text: "Home", href: user ? "/form" : "/" },
    { text: "Generate Trip", href: "/generate-trip", isDisabled: true },
    { text: "My Plans", href: "/my-plans" },

    { text: "My Account", href: "/useraccount" },
    { text: user ? "Log out" : "Log in", href: user ? "#" : "/login" },
  ];


  return (
    <VStack mb={10}>
      <Flex alignItems={'center'} className={styles.wrapper} justifyContent={'space-between'}>
        <IconButton
          size={'md'}
          icon={isOpen ? <CloseIcon /> : <HamburgerIcon />}
          aria-label={'Open Menu'}
          display={{ md: 'none' }}
          onClick={isOpen ? onClose : onOpen}
        />
        <Box width={160}>
          <RouterLink to="/"> {/* Use RouterLink instead of a regular <a> tag */}
            <Image src={logo} alt="Logo" objectFit={'cover'} />
          </RouterLink>
        </Box>
        <Flex alignItems="center" className={styles.topNavbarContainer}>
          <HStack flex="1" display={{ base: 'none', md: 'flex' }}>
            {links.map((link, index) => (
              <Link
                key={index}
                as={RouterLink} // Use RouterLink for internal links
                to={link.href}
                className={`${styles.navbarLink} ${
                  location.pathname.startsWith(link.href) && styles.active
                }`}
                // style={{ cursor: link.isDisabled ? "not-allowed" : "pointer" }}
              >
                {link.text}
              </Link>
            ))}
          </HStack>
          {!user && (
            <Button mb={7} display={{ base: 'none', md: 'flex' }}  right={'1vw'} bg={'#D9D9D9'} top={'2vh'} onClick={() => {navigate('/login')}}>
              Log in
            </Button>
          )}
          {/* sign up */}
          {user && (
            <Button mb={7} display={{ base: 'none', md: 'flex' }} right={'1vw'} bg={'#D9D9D9'} top={'2vh'} onClick={handleLogout}>
              Log out
            </Button>
          )}

        </Flex>
      </Flex>
      {isOpen ? (
          <Box width='100%' pb={4} bg="gray.200" display={{ md: 'none' }} textAlign='center'>
            <Stack as={'nav'} spacing={4}>
            {menuLinks.map((link, index) => (
              <MenuLink
                key={index}
                link={link}
                user={user}
                handleLogout={handleLogout}
              />
            ))}
            </Stack>
          </Box>
        ) : null}
    </VStack>
  );
}
