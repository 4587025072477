import React from 'react';
import { ChakraProvider } from '@chakra-ui/react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { myTheme } from './styles/theme';
import { ColorModeSwitcher } from './ColorModeSwitcher';

import Navbar from './sections/navbar.js';
import UserAccount from './pages/userAccount';
import Contact from './pages/contact.js';
import Form from './pages/form.js';
import MembershipPlan from './pages/membershipPlan.js';
import LoginPage from './pages/login';
import SignupPage from './pages/signup';
import Plan from './pages/plan.js';
import Team from './pages/team.js';
import Activate_Email from './pages/Activate_Email';
import Resend_Email from './pages/Resend_Email';
import Reset_Password from './pages/Reset_Password';
import Reset_Password_Confirm from './pages/Reset_Password_Confirm';
import Home from './pages/home';
import NavbarTrue from './sections/Navbar-true';
import Flights from './pages/flights';
import GenerateTrip from './pages/generateTrip';
import Hotels from './pages/hotels';
import Days from './pages/days';
import PlanFlights from './pages/PlanFlights';
import PlanDays from './pages/PlanDays';
import LoadingDays from './pages/LoadingDays';
import Dining from './pages/dining.js';
import Map from './sections/Map.js';
import PrivacyPolicy from './pages/PrivacyPolicy.js';
import Terms from './pages/Terms.js';

import PrivateRoutes from './PrivateRoutes';
import TripRoutes from './TripRoutes';
import AddPlace from './pages/add_places.js';
import Spot from './pages/spot.js';
import FlightBooking from './pages/flight_booking.js';
import GuestForm from './pages/hotel_booking.js';
import PlaceSchedule from './pages/place-list.js';

function App() {
  console.log('window.location.pathname', window.location.pathname);
  return (
    <ChakraProvider theme={myTheme}>
      <BrowserRouter>
        {/* <ColorModeSwitcher/> */}
        {!window.location.pathname.includes('/useraccount') && <Navbar />}
        <Routes>
          <Route path="/" element={<Home />}></Route>
          <Route path="/contact" element={<Contact />}></Route>
          <Route path="/memberships" element={<MembershipPlan />}></Route>
          <Route path="/login" element={<LoginPage />}></Route>
          <Route path="/signup" element={<SignupPage />}></Route>
          <Route path="/team" element={<Team />}></Route>
          <Route
            path="/activate/:uid/:token"
            element={<Activate_Email />}
          ></Route>
          <Route path="/reset-password" element={<Reset_Password />}></Route>
          <Route path="/resend-email" element={<Resend_Email />}></Route>
          <Route
            path="/password/reset/confirm/:uid/:token"
            element={<Reset_Password_Confirm />}
          ></Route>
          <Route path="/privacy-policy" element={<PrivacyPolicy />}></Route>
          <Route path="/terms-of-conditions" element={<Terms />}></Route>

          <Route element={<PrivateRoutes />}>
            <Route path="/loading" element={<LoadingDays />}></Route>
            <Route path="/bookFlight" element={<FlightBooking/>}></Route>
            <Route path="/bookHotel" element={<GuestForm/>}></Route>
            <Route path="/form" element={<Form />}></Route>
            <Route path="/useraccount" element={<UserAccount />}></Route>
            <Route path="/my-plans" element={<Plan />}></Route>

            <Route
              path="/my-plans/plan-flights"
              element={<PlanFlights />}
            ></Route>
            <Route path="/my-plans/preview/:cP" element={<AddPlace />}></Route>
            <Route
              path="/my-plans/preview/:cP/spot/:index"
              element={<Spot />}
            ></Route>
            <Route path="/my-places" element={<PlaceSchedule />}></Route>

            <Route path="/my-plans/plan-days" element={<PlanDays />}></Route>
            <Route element={<TripRoutes />}>
              <Route path="/generate-trip" element={<GenerateTrip />}>
                <Route path="hotels" element={<Hotels />} />
                <Route path="flights" element={<Flights />} />
                <Route path="days" element={<Days />} />
                <Route path="restaurants" element={<Dining />} />
                {/* Ahmed - Added a parameter in this path to get the longitude and latitude relevant to the place */}
                <Route path="preview/:cP" element={<AddPlace />}></Route>
                <Route
                  path="preview/:cP/spot/:index"
                  element={<Spot />}
                ></Route>
              </Route>
            </Route>
          </Route>
        </Routes>
      </BrowserRouter>
      {/* <Plan /> */}
    </ChakraProvider>
  );
}

export default App;
// {
/* <BrowserRouter>
      <Navbar />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/Home" element={<Home />} />
        <Route path="/Projects" element={<Projects />} />
        <Route path="/Team" element={<Team />} />
        <Route path="/Blog" element={<Blog />} />
        <Route path="/Contact" element={<Contact />} />
        <Route path="/" element={<ProjectsMain />} />
        <Route path="/centennialpark" element={<CentParkPg />} />
        <Route path="/emailAssist" element={<EmailAssistPg />} />
        <Route path="/girlscouts" element={<GirlScoutsPg />} />
        <Route path="/landtrusttn" element={<LandTrustPg />} />
        <Route path="/nash0" element={<NashZeroPg />} />
        <Route path="/ancientartifacts" element={<AncientArtifactsPg />} />
        <Route path="/" element={<BlogMain />} />
        <Route path="/blogPageOne" element={<BlogPageOne />} />
        <Route path="/blogPageTwo" element={<BlogPageTwo />} />
      </Routes>
      <Footer />
    </BrowserRouter> */
// }
