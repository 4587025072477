import React from 'react'
import Rating from './Rating'
import { Badge, Box, Image, Stack, Heading, Text, HStack, Button } from '@chakra-ui/react'
import { useColorModeValue } from '@chakra-ui/react'
import { useNavigate } from 'react-router-dom';

const PlanHotel = ({hotel}) => {
    const overallRating = hotel.overallRating; // Assume overallRating is in the range of 1-100
    const minOverallRating = 1; // Minimum rating value
    const maxOverallRating = 100; // Maximum rating value
    const minStarRating = 1; // Minimum star rating
    const maxStarRating = 5; // Maximum star rating
    const navigate = useNavigate()

    const colorModeValue = useColorModeValue('white', 'gray.800');

    // Calculate the star rating using linear interpolation
    let starRating = minStarRating + ((overallRating - minOverallRating) / (maxOverallRating - minOverallRating)) * (maxStarRating - minStarRating);

    // Round starRating to one decimal point
    starRating = starRating.toFixed(1);
    const handleOfferSelection = async (offerId) => {
        console.log('offer id',offerId);
          const plan = localStorage.getItem('plan_id');
      
        navigate('/bookHotel',{ state: { offer_id:offerId,plan:plan } })

       
      }
  return (
    <Box
        role={'group'}
        p={6}
        maxW={'330px'}
        w={'full'}
        bg={colorModeValue}
        boxShadow={'2xl'}
        rounded={'lg'}
        pos={'relative'}
        zIndex={1}
        >
        <Box
            rounded={'lg'}
            mt={-12}
            pos={'relative'}
            height={'230px'}
            _after={{
            transition: 'all .3s ease',
            content: '""',
            w: 'full',
            h: 'full',
            pos: 'absolute',
            top: 5,
            left: 0,
            backgroundImage: `url(${hotel.hotel_image})`,
            filter: 'blur(15px)',
            zIndex: -1,
            }}
            _groupHover={{
            _after: {
                filter: 'blur(20px)',
            },
            }}
        >
            <Image
                rounded={'lg'}
                height={230}
                width={282}
                objectFit={'cover'}
                src={hotel.hotel_image}
                alt="#"
            />
        </Box>
        <Stack pt={10} align={'center'}>
            <Heading fontSize={'2xl'} fontFamily={'body'} fontWeight={500}>
            {hotel.name}
            </Heading>
            <Text fontSize="sm" color="gray.600">
                {hotel.description}
            </Text>
            <HStack>
            <Text fontSize="sm" color="gray.600">
               Booking ID: {hotel.bookingId}
            </Text>
            <Text fontSize="sm" color="gray.600">
               ConfirmationID: {hotel.providerConfirmationId}
            </Text>
            <Text fontSize="sm" color="gray.600">
               Reference: {hotel.reference}
            </Text>
            </HStack>
            <Badge colorScheme="purple" fontSize="1rem">
                Price: ${hotel.hotel_price}
            </Badge>
            <Text fontSize="sm" color="gray.600">
                Selected for Day {hotel.day_number}
            </Text>
            <Button mt={4} colorScheme="purple" onClick={() => handleOfferSelection(hotel.offerId)} isDisabled={hotel.bookingId !== null}>{hotel.bookingId !== null ? 'Hotel Booked' : 'Book Hotel'} </Button>
            {overallRating && <Rating numReviews={hotel.numberOfReviews} rating={starRating}/>}
        </Stack>
    </Box>
  )
}

export default PlanHotel