import React, { useState, useEffect } from 'react';
import { Flex, Box, Select, Button, Image, Text, Link, useToast } from '@chakra-ui/react';
import axios from 'axios';
import userService from '../services/user.service';
import { useNavigate } from 'react-router-dom';
import { API_URL } from '../services/user.service';

const PlaceSchedule = () => {
  const [places, setPlaces] = useState([]);
  const [schedule, setSchedule] = useState([]);
  const toast = useToast();
  const plan_id = localStorage.getItem('plan_id');
  const navigate = useNavigate()

  useEffect(() => {
    const fetchData = async () => {
      try {
        console.log(plan_id);
        const response = await axios.get(`${API_URL}place-list?planId=${plan_id}`);
        // const response = await userService.getPlacesFromPlan(plan_id=plan_id)
        setPlaces(response.data);
        setSchedule(response.data.map(place => ({ ...place, myday: place.day, day: place.day - response.data[0].day + 1 })));
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);

  const getMaxDay = () => {
    return Math.max(...schedule.map(place => place.day));
  };

  const handleChangeDay = (index, day) => {
    const updatedSchedule = [...schedule];
    const dayDifference = day - updatedSchedule[index].day;
    updatedSchedule[index].myday += dayDifference;
    updatedSchedule[index].day = day;
    setSchedule(updatedSchedule);
  };

  const handleSaveChanges = async () => {
    try {
      // Implement the logic to save changes to the backend
      // For demonstration, we'll just log the updated schedule
      console.log('Updated Schedule:', schedule);
      const response = await userService.PlaceList(schedule,plan_id);
      console.log(response.data);
      toast({
        title: 'Place changed Successfully',
        status: 'success',
        duration: 9000,
        isClosable: true,
      });
      // You can send the updated schedule to the backend here
    } catch (error) {
      console.error('Error saving changes:', error);
    }
  };

  const handleBack = () => {
    navigate(-1);
  }

  return (
    <>
      <Button position="fixed" bottom="10" left="20" colorScheme="purple" zIndex={10} onClick={handleBack}>Back</Button>
      <Button position="fixed" bottom="10" right="20" colorScheme="purple" zIndex={10} onClick={handleSaveChanges}>Save Changes</Button>
      <Flex direction="column" alignItems="center" mt={20}>
        {schedule.map((place, index) => (
          <Box key={place.id} mb={8} p={6} borderWidth="1px" borderRadius="lg" width="500px">
            <Flex alignItems="center">
              <Image src={place.image} alt={place.name} boxSize="100px" objectFit="cover" borderRadius="md" mr={6} />
              <Box>
                <Text fontSize="xl" fontWeight="bold">{place.name}</Text>
                <Text>{place.phoneNumber}</Text>
                <Text>{place.description}</Text>
                <Link href={place.website} color="blue.500" isExternal>
                  {place.website}
                </Link>
              </Box>
            </Flex>
            <Flex mt={4} alignItems="center">
              <Text mr={4}>Day:</Text>
              <Select
                value={place.day}
                onChange={(e) => handleChangeDay(index, e.target.value)}
              >
                {Array.from({ length: getMaxDay() }, (_, i) => i + 1).map((day) => (
                  <option key={day} value={day}>
                    Day {day}
                  </option>
                ))}
              </Select>
            </Flex>
          </Box>
        ))}
      </Flex>
    </>
  );
};

export default PlaceSchedule;
