import React, { useState } from 'react';

import {
    Box,
    Flex,
    Text,
    Badge,
    VStack, HStack, Stack,
    Spacer,
    Icon,
    List, ListItem, UnorderedList,
    Stat, StatLabel, StatNumber, StatGroup,
    Button,
    Collapse,
  } from '@chakra-ui/react';
  import { useLocation } from 'react-router-dom';
import TravelerForm from './travelForm';

const FlightBooking = () => {
    const location = useLocation();
    const flights = location.state && location.state.flights;
  
    return(  
        <div>
    <Badge colorScheme="purple" fontSize="lg" fontWeight="bold">
    AIRLINE: {flights.carrier_code}
  </Badge>
  <TravelerForm/>
  </div>);
};

export default FlightBooking;
