import { useForm, Controller } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
// import { MultiSelect } from 'chakra-multiselect';
import {
  Input,
  Grid,
  GridItem,
  Select,
  Button,
  InputGroup,
  InputRightElement,
  FormControl,
  FormLabel,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Box,
} from '@chakra-ui/react';
import { Stack } from '@chakra-ui/react';
import React, { useRef, useState, useEffect } from 'react';
import styles from '../styles/index.module.scss';
import { City } from 'country-state-city';
import { useDispatch } from 'react-redux';
import userService from '../services/user.service';
import { Hourglass } from 'react-loader-spinner';
import { MultiSelect } from "react-multi-select-component";


export default function Form() {
  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      budget: '',
      eatingPreferences: '',
      interestingActivities: '',
      otherPreferences: '',
      numPeople: 0,
      style: '',
    },
  });
  const datepickerRef = useRef(null);
  const [countries, setCountries] = useState([]);
  const [destinationCities, setDestinationCities] = useState([])
  const [originCities, setOriginCities] = useState([])
  const [csvArray, setCsvArray] = useState([]);
  
  const [selectedStartDate, setSelectedStartDate] = useState(null);
  const [selectedEndDate, setSelectedEndDate] = useState(null);
  const [selectedDestinationCity, setSelectedDestinationCity] = useState(null);
  const [selectedDestinationCountry, setSelectedDestinationCountry] =
    useState(null);
  const [selectedOriginCity, setSelectedOriginCity] = useState(null);
  const [selectedOriginCountry, setSelectedOriginCountry] = useState(null);

  const [destinationIataCode, setDestinationIataCode] = useState('');
  const [originIataCode, setOriginIataCode] = useState('');
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [addMore, setAddMore] = useState(false);

  const [selectedCities, setSelectedCities] = useState([]);
  const [cityOptions, setCityOptions] = useState([]);

  useEffect(() => {
    const csvUrl = process.env.PUBLIC_URL + '/IATA-Code-List.csv';

    fetch(csvUrl)
      .then((response) => response.text())
      .then((csvData) => {
        // Parse the CSV data
        const rows = csvData.split('\n');
        const headers = rows[0].split(',');
        const parsedCsvArray = [];

        for (let i = 1; i < rows.length; i++) {
          const row = rows[i].split(',');
          const rowData = {};

          for (let j = 0; j < headers.length; j++) {
            rowData[headers[j]] = row[j];
          }

          parsedCsvArray.push(rowData);
        }

        // Set csvArray in the component state
        setCsvArray(parsedCsvArray);

        // Extract unique country names from the CSV data
        const uniqueCountries = Array.from(new Set(parsedCsvArray.map((row) => row.Country)));
        setCountries(uniqueCountries);
      })
      .catch((error) => {
        console.error('Error fetching CSV data: ', error);
      });
  }, []);



  // const [numPeople, setNumPeople] = useState(0);
  const navigate = useNavigate();
  const inputRef = useRef(null);

  const extractCityName = (city) => {
    if (!city) {
      return;
    }
  
    let cityName = city;
    const dashIndex = city.indexOf('-');
    const parenIndex = city.indexOf('(');
  
    if (dashIndex !== -1 && parenIndex !== -1) {
      // Handle cases where both "-" and "(" are present
      if (dashIndex < parenIndex) {
        cityName = city.substring(0, dashIndex).trim();
      } else {
        cityName = city.substring(0, parenIndex).trim();
      }
    } else if (dashIndex !== -1) {
      // Handle cases with "-"
      cityName = city.substring(0, dashIndex).trim();
    } else if (parenIndex !== -1) {
      // Handle cases with "("
      cityName = city.substring(0, parenIndex).trim();
    }
  
    return cityName;
  };

  const handleDestinationCityChange = event => {
    const selectedCity = event.target.value;
    if (selectedCity) {
      setSelectedDestinationCity(selectedCity);
      // Find the IATA code for the selected city
      const data = csvArray.find((row) => row['City/Airport'] === selectedCity);
      const iataCode = Object.values(data)[2].trim(); 
      console.log(iataCode)
      setDestinationIataCode(iataCode)

      const fullCityNames = destinationCities.map(city => {
        const data = csvArray.find(row => row['City/Airport'] === city);
        const iataCode = data ? Object.values(data)[2].trim() : null;
        return {
          label: extractCityName(city),
          value: iataCode
        };
      });
      const uniqueCities = Array.from(new Map(fullCityNames.map(city => [city.label, city])).values());
      setCityOptions(uniqueCities)
    }

  };

  const handleDestinationCountryChange = event => {
    const selectedCountry = event.target.value;
    setSelectedDestinationCountry(selectedCountry);

    // Filter cities based on the selected country
    const countryCities = csvArray.filter((row) => row.Country === selectedCountry);

    setDestinationCities(countryCities.map((row) => row['City/Airport']));
  };

  const handleOriginCityChange = event => {
    const selectedCity = event.target.value;
    if (selectedCity) {
      setSelectedOriginCity(selectedCity);
      // Find the IATA code for the selected city
      const data = csvArray.find((row) => row['City/Airport'] === selectedCity);
      const iataCode = Object.values(data)[2].trim(); 
      console.log(iataCode)
      setOriginIataCode(iataCode)
    }
  };

  const handleOriginCountryChange = event => {
    const selectedCountry = event.target.value;
    setSelectedOriginCountry(selectedCountry);

    // Filter cities based on the selected country
    const countryCities = csvArray.filter((row) => row.Country === selectedCountry);

    setOriginCities(countryCities.map((row) => row['City/Airport']));
  };

  const handleSingleCity = () => {
    setAddMore(false)
    setSelectedCities([])
  }

  const onSubmit = async data => {
    // Redirect to '/generate-trip' with data
    onOpen();

    try {
      const plan = {
        name: extractCityName(selectedDestinationCity),
      };
      const response_plan = await userService.createPlan(plan);
      localStorage.setItem('plan_id', response_plan.data.id);
      localStorage.setItem('selectedDestinationCity', extractCityName(selectedDestinationCity));
      localStorage.setItem('selectedStartDate', selectedStartDate);
      localStorage.setItem('selectedEndDate', selectedEndDate);
      localStorage.setItem('originIataCode', originIataCode);
      localStorage.setItem('destinationIataCode', destinationIataCode);
      localStorage.setItem('numPeople', data.numPeople);
      localStorage.setItem('budget', data.budget);
      localStorage.setItem('selectedCities', JSON.stringify(selectedCities));

      const submission = {
        selectedStartDate: new Date(selectedStartDate).toISOString().split('T')[0],
        selectedEndDate: new Date(selectedEndDate).toISOString().split('T')[0],
        selectedDestinationCity: selectedDestinationCity,
        selectedOriginCity: selectedOriginCity,
        plan_id: response_plan.data.id,
        selectedCities: selectedCities,
        ...data,
      };

      const response = userService.createDays(submission);
      // console.log(response)
    } catch (error) {
      // Handle any errors if needed
      console.error('Error during submission:', error);
    } finally {
      // Close the modal in the 'finally' block to ensure it runs regardless of success or failure
      onClose();
      navigate('/generate-trip/flights');
    }
  };

  return (
    <div className="container mx-auto p-4">
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="grid sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
          <Grid
            className={styles.grid}
            templateColumns={{
              base: 'repeat(1, 1fr)',
              sm: 'repeat(2, 1fr)',
              md: 'repeat(3, 1fr)',
            }}
            gap={4}
          >
            <GridItem colSpan={1} style={{ marginBottom: 40 }}>
              <h1>Where do you want to go?</h1>
              <FormControl>
                <FormLabel>Select a Country</FormLabel>
                <Select
                  placeholder="Select a country"
                  onChange={event =>
                    handleDestinationCountryChange(event)
                  }
                  required
                >
                  {countries && countries.map((country) => (
                    <option key={country} value={country}>
                      {country}
                    </option>
                  ))}
                </Select>
              </FormControl>
              {selectedDestinationCountry && (
                <FormControl mb={4}>
                  <FormLabel>Select a City</FormLabel>
                  <Select
                    placeholder="Select a city"
                    onChange={event =>
                      handleDestinationCityChange(event)
                    }
                  >
                    {destinationCities.map((city) => (
                      <option key={city} value={city}>
                        {city}
                      </option>
                    ))}
                  </Select>
                </FormControl>
              )}
              {selectedDestinationCity && addMore && (
                <Box maxWidth={420}>
                  <MultiSelect
                    options={cityOptions}
                    value={selectedCities}
                    onChange={setSelectedCities}
                    labelledBy="Select"
                  />
                </Box>)}
              {selectedDestinationCity && !addMore && (
                <Button
                  marginTop={5}
                  className={styles.createTrip}
                  onClick={() => setAddMore(true)}
                >
                  Add more cities
                </Button>
              )}
              {selectedDestinationCity && addMore && (
                <Button
                  marginTop={5}
                  className={styles.createTrip}
                  onClick={handleSingleCity}
                >
                  Only single city
                </Button>
              )}
            </GridItem>{' '}
            <GridItem colSpan={1} style={{ marginBottom: 40 }}>
              <h1>From where do you want to go?</h1>
              <FormControl>
                <FormLabel>Select a Country</FormLabel>
                <Select
                  placeholder="Select a country"
                  onChange={event =>
                    handleOriginCountryChange(event)
                  }
                  required
                >
                  {countries && countries.map((country) => (
                    <option key={country} value={country}>
                      {country}
                    </option>
                  ))}
                </Select>
              </FormControl>
              {selectedOriginCountry && (
                <FormControl>
                  <FormLabel>Select a City</FormLabel>
                  <Select
                    placeholder="Select a city"
                    onChange={event =>
                      handleOriginCityChange(event)
                    }
                  >
                    {originCities.map((city) => (
                      <option key={city} value={city}>
                        {city}
                      </option>
                    ))}
                  </Select>
                </FormControl>
              )}
            </GridItem>
            <GridItem colSpan={1}>
              <h1>What is your budget?</h1>
              <Controller
                name="budget"
                control={control}
                defaultValue=""
                rules={{ required: 'Budget is required' }}
                render={({ field }) => (
                  <Input
                    className={styles.inputDiv}
                    type='number'
                    placeholder="Enter your budget"
                    {...field}
                    prefix="$"
                    isInvalid={errors?.budget}
                    {...register('budget')}
                  />
                )}
              />
              {errors?.budget && (
                <span style={{ color: 'red' }}>{errors.budget.message}</span>
              )}
            </GridItem>
            <GridItem colSpan={1}>
              <h1>Eating preferences?</h1>
              <Controller
                name="eatingPreferences"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <Input
                    className={styles.inputDiv}
                    placeholder="Eating preferences (Optional)"
                    {...field}
                    {...register('eatingPreferences')}
                  />
                )}
              />
            </GridItem>
            <GridItem
              colSpan={1}
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                marginBottom: 40,
              }}
            >
              <div className={styles.datePickerWrapper}>
                <h1>When are you planning to travel?</h1>
                <Controller
                  name="date"
                  control={control}
                  defaultValue=""
                  rules={{
                    required: `${
                      !selectedEndDate && !selectedStartDate
                        ? 'Start and End date is required'
                        : !selectedStartDate
                        ? 'Start date is required'
                        : !selectedEndDate
                        ? 'End date is rewuired'
                        : ''
                    }`,
                  }}
                  render={({ field }) => (
                    <DatePicker
                      selected={selectedStartDate}
                      onChange={dates => {
                        const [start, end] = dates;
                        setSelectedStartDate(start);
                        setSelectedEndDate(end);
                      }}
                      startDate={selectedStartDate}
                      endDate={selectedEndDate}
                      selectsRange={true}
                      ref={datepickerRef}
                      placeholderText="Select Date"
                      className={styles.datePicker}
                      dateFormat="yyyy-MM-dd"
                    />
                  )}
                />
                {errors?.date && (
                  <span style={{ color: 'red' }}>{errors.date.message}</span>
                )}
              </div>
            </GridItem>
            <GridItem colSpan={1}>
              <h1>What Activities are you interested in?</h1>
              <Controller
                name="interestingActivities"
                control={control}
                defaultValue=""
                rules={{ required: 'Activities are required' }}
                render={({ field }) => (
                  <Input
                    className={styles.inputDiv}
                    placeholder="Activities (Optional)"
                    {...field}
                    {...register('interestingActivities')}
                  />
                )}
              />
            </GridItem>
            <GridItem colSpan={1}>
              <h1>Other preferences</h1>
              <Controller
                name="otherPreferences"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <Input
                    className={styles.inputDiv}
                    placeholder="Other preferences (Optional)"
                    {...field}
                  />
                )}
              />
            </GridItem>
            <GridItem colSpan={1}>
              <h1>How many people are going?</h1>
              <Controller
                name="numPeople"
                control={control}
                defaultValue=""
                rules={{ required: 'Number of people is required' }}
                render={({ field }) => (
                  <Input
                    className={styles.inputDiv}
                    placeholder="Number of people"
                    type="number"
                    {...field}
                    prefix="$"
                    isInvalid={errors?.numPeople}
                    {...register('numPeople')}
                  />
                )}
              />
              {errors?.numPeople && (
                <span style={{ color: 'red' }}>{errors.numPeople.message}</span>
              )}
            </GridItem>
            <GridItem colSpan={1}>
              <h1>Style</h1>
              <Controller
                name="style"
                control={control}
                defaultValue=""
                rules={{ required: 'Style is required' }}
                render={({ field }) => (
                  <Select
                    className={styles.inputDiv}
                    placeholder="Select a style"
                    {...field}
                    isInvalid={errors?.style}
                    {...register('style')}
                  >
                    <option value="intellectual">Intellectual</option>
                    <option value="local">Local</option>
                    <option value="slow">Slow</option>
                    <option value="rommantic">Romantic</option>
                    <option value="firsttime">First-Time</option>
                    <option value="valanced">Balanced</option>
                  </Select>
                )}
              />
              {errors?.style && (
                <span style={{ color: 'red' }}>{errors?.style?.message}</span>
              )}
            </GridItem>
          </Grid>

          <Modal
            style={{
              bgcolor: 'white',
              color: '#fff',
              zIndex: theme => theme.zIndex.drawer + 1,
            }}
            isOpen={isOpen}
            onClose={() => {}} // Provide an empty function to prevent it from closing
            size="full" // Set the modal size to full
          >
            <ModalOverlay />
            <ModalContent width="100%" height="100vh">
              <ModalBody
                display="flex"
                justifyContent="center"
                alignItems="center"
                height="100%"
              >
                <Hourglass
                  visible={true}
                  height="200"
                  width="200"
                  ariaLabel="hourglass-loading"
                  wrapperStyle={{}}
                  wrapperClass=""
                  colors={['#72a1ed', '#CCA6F9']}
                />
              </ModalBody>
            </ModalContent>
          </Modal>
        </div>
        <div className={styles.createTripButton}>
          <Button className={styles.createTrip} type="submit">
            Create my trip!
          </Button>
        </div>
      </form>
    </div>
  );
}
