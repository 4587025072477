import React, { useState } from 'react';
import {
  Box,
  Text,
  Button,
  useColorModeValue,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Progress,
  Image,
  Spinner,
  Stack,
  VStack, Alert, AlertIcon, Flex, useToast, Link, Collapse, Center
} from '@chakra-ui/react';
import {
  CheckIcon,
  CloseIcon,
} from '@chakra-ui/icons';
import userService from '../services/user.service';
import { useDispatch, useSelector } from 'react-redux';
import { selectDay } from '../slices/daySlice';
import { Oval } from 'react-loader-spinner';
import { useNavigate } from 'react-router-dom';
import ProgressBar from '@ramonak/react-progress-bar';
import Rating from './Rating'
import MapNew from './MapNew';
import './main.css'
import { ChevronDownIcon, ChevronUpIcon } from '@chakra-ui/icons';
import MarkerIcon from '../assets/restaurant_icon.png'

export default function Restaurant({ restaurant }) {
  const colorModeValue = useColorModeValue('white', 'gray.800');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [restaurantDetails, setRestaurantDetails] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [noOffersModal, setNoOffersModal] = useState(false)
  const toast = useToast()
  const navigate = useNavigate()
  const [isOpen, setIsOpen] = useState(false)

  const currentDay = useSelector(selectDay)

  const fetchRestaurantDetails = async () => {
    setIsLoading(true);

    try {
      const data = {
        location_id: restaurant.location_id, // Use the appropriate key for hotel ID
      };
      const response = await userService.restaurantDetails(data);
      if (response.status == 204) {
        setNoOffersModal(true);
        console.log('No offers found for this hotel currently!')
      }
      else {
        console.log(response.data)
        setRestaurantDetails(response.data);
      }
    } catch (error) {
      setNoOffersModal(true);
      console.error('Error fetching hotel details:', error);
    } finally {
      setIsLoading(false);
      setIsModalOpen(true);
    }

  };

  return (
    <>
      <Box
        maxW={'350px'}
        w={'100%'}
        p={4}
        borderWidth={1}
        borderRadius="lg"
        bg={colorModeValue}
        transition="transform 0.2s"
        _hover={{ transform: 'scale(1.05)' }}
        _active={{ transform: 'scale(0.95)' }}
        >
        <Text fontSize="xl" fontWeight="bold" color="black"> {/* Add color attribute to Text */}
            {restaurant.name}
        </Text>
        <Text fontSize="lg" fontWeight="normal" color="gray.600"> {/* Adjust font size, use a gray color, and set normal weight */}
            {restaurant.address}
        </Text>
        <Button
            size="sm"
            mt={4}
            alignSelf="flex-end"
            colorScheme="purple"
            onClick={fetchRestaurantDetails}
        >
            See Details
        </Button>
        </Box>

        {restaurantDetails && (
  <Modal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} size="6xl">
    <ModalOverlay />
    <ModalContent height="fit-content">
      <Flex flexDirection={{ base: 'column', md: 'row' }} alignItems={{ base: 'center', md: 'initial' }}>
        <Box flex={1} p={4}>
          <ModalHeader>{restaurantDetails.name}</ModalHeader>
          <ModalCloseButton />
          <ModalBody >
            <VStack align='start' spacing={1}>
              <Image src={restaurantDetails.image} alt={restaurantDetails.name} />
              <Button
                colorScheme="purple"
                my={2}
                onClick={async () => {
                  const inputData = {
                    name: restaurantDetails.name,
                    description: restaurantDetails.description,
                    restaurant_image: restaurantDetails.image,
                    rating: restaurantDetails.rating ? restaurantDetails.rating : null,
                    num_reviews: restaurantDetails.num_reviews ? restaurantDetails.num_reviews : null,
                    address: restaurantDetails.address ? restaurantDetails.address : null,
                    email: restaurantDetails.email ? restaurantDetails.email : null,
                    phone: restaurantDetails.phone ? restaurantDetails.phone : null,
                    day: currentDay.day_id,
                    day_number: currentDay.day_number + 1,
                    opening_hours: restaurantDetails.weekday_text,
                    longitude: restaurantDetails.longitude,
                    latitude: restaurantDetails.latitude,
                  };
                  const plan_id = localStorage.getItem('plan_id');
                  try {
                    const response = await userService.addRestaurant(inputData, plan_id);
                    toast({
                      title: 'Restaurant Selected Successfully',
                      description: `The Restaurant was saved for Day ${currentDay.day_number + 1}.`,
                      status: 'success',
                      duration: 9000,
                      isClosable: true,
                    });
                    navigate('/generate-trip/days');
                  } catch {
                    toast({
                      title: 'Restaurant Selection Unsuccessful',
                      description: 'Some Error occurred. Please try again',
                      status: 'error',
                      duration: 9000,
                      isClosable: true,
                    });
                  }
                  setIsModalOpen(false);
                }}
              >
                Select this restaurant
              </Button>
              {restaurantDetails.description && <Text fontWeight="bold" fontSize="lg">Description</Text>}
              {restaurantDetails.description && <Text>{restaurantDetails.description}</Text>}
              {restaurantDetails.email && <Text fontWeight="bold" fontSize="lg">Email</Text>}
              <Text fontSize="sm">
                <Link href={`mailto:${restaurantDetails.email}`}>{restaurantDetails.email}</Link>
              </Text>
              {restaurantDetails.phone && <Text fontWeight="bold" fontSize="lg">Phone</Text>}
              <Text fontSize="sm">
                <Link href={`tel:${restaurantDetails.phone}`}>{restaurantDetails.phone}</Link>
              </Text>
              <Link href={restaurantDetails.website} fontSize="sm" color="teal.500" isExternal>
                Visit Website
              </Link>
              {restaurantDetails.rating ? <Rating numReviews={restaurantDetails.num_reviews} rating={restaurantDetails.rating} /> : null}
              {restaurantDetails.weekday_text && <Box display="flex" alignItems="center" cursor="pointer" onClick={() => setIsOpen(!isOpen)}>
                <Text fontWeight="bold" fontSize="lg" mr={2}>
                  Opening Hours
                </Text>
                {isOpen ? <ChevronUpIcon boxSize={5} /> : <ChevronDownIcon boxSize={5} />}
              </Box>}
              {restaurantDetails.weekday_text && <Collapse in={isOpen} animateOpacity>
                <Text fontSize="sm">
                  {restaurantDetails.weekday_text.map((day, index) => (
                      <div key={index}>{day}</div>
                    ))}
                </Text>
              </Collapse>}
              <Button mt={2} colorScheme="purple" onClick={() => setIsModalOpen(false)}>
                Close
              </Button>
            </VStack>
          </ModalBody>
        </Box>

        {/* Right half - Map */}
          <ModalBody alignItems='center' justifyContent='center' width='100%' height='100%'>
            <Center flex={1} display={{ base: 'none', md: 'block' }} alignItems='center' justifyContent='center' height='100%' width='100%'>
              <MapNew restLatitude={restaurantDetails.latitude} restLongitude={restaurantDetails.longitude} MarkerIcon={MarkerIcon}/>
            </Center>
          </ModalBody>
      </Flex>
      {/* On smaller screens, show the map below the content */}
      <Box display={{ base: 'block', md: 'none' }}>
        <ModalBody alignItems='center' justifyContent='center' width='100%'>
          <MapNew restLatitude={restaurantDetails.latitude} restLongitude={restaurantDetails.longitude} MarkerIcon={MarkerIcon} />
        </ModalBody>
      </Box>
    </ModalContent>
  </Modal>
)}


  {isLoading && (
      <Modal isOpen={isLoading}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Loading</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
          <Flex justifyContent="center" alignItems="center">
            <Oval
              height={80}
              width={80}
              color="#4fa94d"
              secondaryColor="#4fa94d"
              strokeWidth={2}
              strokeWidthSecondary={2}
            />
          </Flex>
          </ModalBody>
        </ModalContent>
      </Modal>
    )}

    {noOffersModal && (
      <Modal isOpen={noOffersModal} onClose={() => setNoOffersModal(false)}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>No Offers Found</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text fontSize="lg" fontWeight="bold">
              No offers found for this hotel currently.
            </Text>
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="purple" onClick={() => setNoOffersModal(false)}>
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    )}

    </>
  );
}
