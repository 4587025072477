import React, { useState } from 'react';
import {
  Box,
  Button,
  FormControl,
  Input,
  VStack,
  Image,
  Heading,
  Alert,
  Text,
  FormErrorMessage
} from '@chakra-ui/react';
import styles from '../styles/index.module.scss';
import cancelIcon from '../assets/cancelIcon.svg';
import Logo from '../assets/logo.png';
import { useFormik } from 'formik';
import { useNavigate, NavLink } from 'react-router-dom';
import * as Yup from 'yup';
import AuthService from '../services/auth.service';
import { useAuthContext } from '../contexts/AuthContext';
import { resetPassword } from '../constants';
import CustomModal from '../sections/CustomModal'

export default function Reset_Password(){
    
    const validationSchema = Yup.object().shape({
        email: Yup.string().email('Invalid email address').required('Email is required'),
      });
      
    const [loginError, setLoginError] = useState(null);   
    const [success, setSuccess] = useState(false);

    const handleSuccess = () => {
        setSuccess(false);
      };
    
    const formik = useFormik({
        initialValues: {
            email: '',
        },
        validationSchema,
        onSubmit: async (values) => {
        try {
            const response = await AuthService.resetPassword(values)
            // Handle successful registration here
            // console.log('Login response:', response1);
            setSuccess(true)
        } catch (error) {
            // Handle login error here
            console.error('Login error:', error);
            setLoginError('An account with the given Email Address does not exist!'); // Set the error message
        }
        },
    });

    return (
        <Box>
          <Box className={styles.modalWrapper}>
            <VStack spacing={4}>
              <Box
                display={'flex'}
                w={'100%'}
                alignItems={'center'}
                justifyContent={'start'}
                gap={10}
              >
              <Heading fontWeight="500" fontSize="30" width='100%' textAlign='center' >
                Reset password
              </Heading>
              </Box>
              <form style={{width:'100%'}} onSubmit={formik.handleSubmit}>
                    {loginError && (
                    <Alert status="error" sx={{ mb: 2 }}>
                        {loginError}
                    </Alert>
                    )}
                <FormControl isInvalid={formik.touched.email && formik.errors.email}>
                  <Input
                    className={styles.inputField}
                    id="email"
                    placeholder="Email Address"
                    name="email"
                    autoComplete="email"
                    value={formik.values.email}
                    onChange={formik.handleChange}
                    width="100%"
                    required
                  />
                  <FormErrorMessage>{formik.errors.email}</FormErrorMessage>
                </FormControl>
                <NavLink to='/login'><Text color={'blue.400'}>Back to Login?</Text></NavLink>
                <Box w={'100%'} display={'flex'} justifyContent={'center'}>
                  <Button className={styles.saveDeatilsButton} type="submit">
                    Confirm
                  </Button>
                </Box>
              </form>
             
            </VStack>
  
            <CustomModal isOpen={success} onClose={handleSuccess} title='Reset Password' description={resetPassword}/>            
          </Box>
          
        </Box>
      );
}

// export const resetPasswordAction = async ({request}) => {
//     const data = await request.formData()
//     const submission = {
//         email: data.get('email'),
//     }
//     // error handling
//     try{
//         const response = await AuthService.resetPassword(submission)
//         // console.log(response.data)
//         return redirect('/login');
//     }catch(e){
//         throw e
//     }

// }