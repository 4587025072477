import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  places: [],
};

export const placesSlice = createSlice({
  name: 'places',
  initialState,
  reducers: {
    placesAdded: {
      reducer(state, action) {
        state.places = action.payload; // Replace the entire places array
      },
    },
  },
});

export const selectPlaces = state => state.places.places;

export const { placesAdded } = placesSlice.actions;

export default placesSlice.reducer;
