import React, { useEffect, useState } from 'react';

import styles from '../styles/index.module.scss';
import pinIcon from '../assets/pinIcon.svg'; // Import the SCSS file
import userService from '../services/user.service';

import { BsStar, BsStarFill, BsStarHalf } from 'react-icons/bs';
import { FaShareSquare } from 'react-icons/fa';
import {
  Center,
  useColorModeValue,
  Heading,
  Text,
  Stack,
  Input,
  Image,
  Flex,
  Box,
  useToast,
  Button,
  Icon,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  IconButton,
} from '@chakra-ui/react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
} from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { ChevronUpIcon, SettingsIcon } from '@chakra-ui/icons'; // Import the up arrow icon
import { Tabs, TabList, TabPanels, Tab, TabPanel } from "@chakra-ui/react";

// 1. Import

export default function Plan() {
  const [planData, setPlanData] = useState([]);
  const [sharedPlanData, setSharedPlanData] = useState([]);
  const colorModeValue = useColorModeValue('white', 'gray.800');
  const navigate = useNavigate()
  const [showScroll, setShowScroll] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [email, setEmail] = useState('');
  const [selectedPlan, setSelectedPlan] = useState(null)
  const [tabIndex, setTabIndex] = useState(0);
  const toast = useToast()

  const handleSharePlan = (plan) => {
    // console.log(plan.id)
    setSelectedPlan(plan)
    setIsOpen(true);
  }

  const handleCloseModal = () => {
    setIsOpen(false);
    setEmail(''); // Reset email state on modal close
    setSelectedPlan(null)
  };

  const handleAddUser = async () => {
    // Implement your logic for adding a user with the provided email
    try {
      console.log(selectedPlan, email)
      const data = {
        plan_id: selectedPlan.id,
        collaborator_email: email,
      }
      const response = await userService.addUser(data)
      toast({
        title: 'User added successfully',
        description: `User with ${email} was added to your plan`,
        status: 'success',
        duration: 9000,
        isClosable: true,
      });
      console.log(response)
    } catch (error) {
      const errorMessage = error.response.data.error || 'Unknown error occurred';
      toast({
        title: 'User Collab Failed',
        description: `${errorMessage}`,
        status: 'error',
        duration: 9000,
        isClosable: true,
      });
      console.log(error)
    }
    // Close the modal
    handleCloseModal();
  };

  const getData = async () => {
    const response = await userService.getPlan();
    setPlanData(response?.data);

    const response1 = await userService.getSharedPlan();
    setSharedPlanData(response1?.data);
    console.log(response1, 'response');
  };

  useEffect(() => {
    getData();
    window.addEventListener('scroll', handleScroll);

    // Cleanup event listener on unmount
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  // Function to handle scroll event and show/hide scroll-to-top button
  const handleScroll = () => {
    if (window.scrollY > 300) {
      setShowScroll(true);
    } else {
      setShowScroll(false);
    }
  };

  // Function to scroll to the top of the page
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  const handlePlanClick = (plan) => {
    localStorage.removeItem('budget')
    localStorage.setItem('plan_id', plan.id)
    localStorage.setItem('plan_name', plan.name)
    navigate('/my-plans/plan-flights')
  }

  const handleDeletePlan = async (plan) => {
    try {
      const response = await userService.deletePlan(plan.id)
      toast({
        title: `Plan Deleted`,
        description: `Your plan to ${plan.name} was deleted Successfully.`,
        status: 'success',
        duration: 9000,
        isClosable: true,
      })
    } catch (error) { // Add the "error" parameter
      toast({
        title: 'Plan Deleted',
        description: `Plan deletion unsuccessful, Please try again!`,
        status: 'error',
        duration: 9000,
        isClosable: true,
      });
      console.log(error)
    } finally {
      getData()
    }
  }

  return (
    <div className={styles.myPlansContainer}>
      {showScroll && (
        <Button
          colorScheme="purple"
          position="fixed"
          right="2rem"
          bottom="2rem"
          onClick={scrollToTop}
          zIndex={5}
        >
          <Icon as={ChevronUpIcon} w={8} h={8} /> {/* Add the up arrow icon */}
        </Button>
      )}
      <Tabs index={tabIndex} onChange={(index) => setTabIndex(index)} colorScheme='purple'>
        <TabList mb={10} width='100%' justifyContent={'center'} alignItems={'center'}>
          <Tab>Your Plans</Tab>
          <Tab>Shared Plans</Tab>
        </TabList>

        <TabPanels>
          <TabPanel>
            <Flex
              justifyContent={'space-around'}
              gap={100}
              flexWrap={'wrap'}
              align="center"
            >
              {planData?.map((plan, index) => (
                <Box
                  role={'group'}
                  p={6}
                  maxW={'330px'}
                  w={'full'}
                  bg={colorModeValue}
                  boxShadow={'2xl'}
                  rounded={'lg'}
                  pos={'relative'}
                  zIndex={1}
                >
                  <Box
                    rounded={'lg'}
                    mt={-12}
                    pos={'relative'}
                    height={'230px'}
                    _after={{
                      transition: 'all .3s ease',
                      content: '""',
                      w: 'full',
                      h: 'full',
                      pos: 'absolute',
                      top: 5,
                      left: 0,
                      backgroundImage: `url(${plan.image})`,
                      filter: 'blur(15px)',
                      zIndex: -1,
                    }}
                    _groupHover={{
                      _after: {
                        filter: 'blur(20px)',
                      },
                    }}
                    onClick={() => handlePlanClick(plan)}
                    cursor='pointer'
                  >
                    <Image
                      rounded={'lg'}
                      height={230}
                      width={282}
                      objectFit={'cover'}
                      src={plan.image}
                      alt="#"
                    />
                  </Box>
                  <Stack pt={10} align={'center'}>
                    <Heading fontSize={'2xl'} fontFamily={'body'} fontWeight={500}>
                      Your plan for {plan.name}
                    </Heading>
                    <Text fontSize="sm" color="gray.600">
                      Created at: {new Date(plan.created_at).toLocaleDateString()}
                    </Text>
                    <Button
                      colorScheme="red"
                      size="sm"
                      onClick={() => handleDeletePlan(plan)}
                    >
                      Delete
                    </Button>
                  </Stack>
                  {/* Settings menu */}
                  <Menu placement="bottom-end">
                    <MenuButton
                      as={IconButton}
                      aria-label="Settings"
                      icon={<FaShareSquare />}
                      position="absolute"
                      bottom="1rem"  // Set the position to bottom
                      right="1rem"
                      zIndex={2}
                    />
                    <MenuList>
                      <MenuItem onClick={() => handleSharePlan(plan)}>Share</MenuItem>
                      {/* Add more menu items as needed */}
                    </MenuList>
                  </Menu>
                </Box>
              ))}
            </Flex>
          </TabPanel>

          <TabPanel>
            {/* Shared Plans tab */}
            <Flex
              justifyContent={'space-around'}
              gap={100}
              flexWrap={'wrap'}
              align="center"
            >
              {sharedPlanData?.map((plan, index) => (
                <Box
                  role={'group'}
                  p={6}
                  maxW={'330px'}
                  w={'full'}
                  bg={colorModeValue}
                  boxShadow={'2xl'}
                  rounded={'lg'}
                  pos={'relative'}
                  zIndex={1}
                >
                  <Box
                    rounded={'lg'}
                    mt={-12}
                    pos={'relative'}
                    height={'230px'}
                    _after={{
                      transition: 'all .3s ease',
                      content: '""',
                      w: 'full',
                      h: 'full',
                      pos: 'absolute',
                      top: 5,
                      left: 0,
                      backgroundImage: `url(${plan.plan.image})`,
                      filter: 'blur(15px)',
                      zIndex: -1,
                    }}
                    _groupHover={{
                      _after: {
                        filter: 'blur(20px)',
                      },
                    }}
                    onClick={() => handlePlanClick(plan.plan)}
                    cursor='pointer'
                  >
                    <Image
                      rounded={'lg'}
                      height={230}
                      width={282}
                      objectFit={'cover'}
                      src={plan.plan.image}
                      alt="#"
                    />
                  </Box>
                  <Stack pt={10} align={'center'}>
                    <Heading fontSize={'2xl'} fontFamily={'body'} fontWeight={500}>
                      Your plan for {plan.plan.name}
                    </Heading>
                    <Text fontSize="sm" color="gray.600">
                      Created at: {new Date(plan.plan.created_at).toLocaleDateString()}
                    </Text> 
                    <Text fontSize="sm" color="gray.600">
                      Shared by: {plan.creator.first_name} {plan.creator.last_name}
                    </Text>
                    {/* <Button
                      colorScheme="red"
                      size="sm"
                      onClick={() => handleDeletePlan(plan.plan)}
                    >
                      Delete
                    </Button> */}
                  </Stack>
                </Box>
              ))}
            </Flex>
          </TabPanel>
        </TabPanels>
      </Tabs>
      <Flex
        justifyContent={'space-around'}
        gap={100}
        flexWrap={'wrap'}
        align="center"
      >
        <Modal isOpen={isOpen} onClose={handleCloseModal}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Share Plan</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <Input
                placeholder="Email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              <Button mt={4} colorScheme="purple" onClick={handleAddUser}>
                Add User
              </Button>
            </ModalBody>
          </ModalContent>
        </Modal>
      </Flex>
    </div>
  );
}